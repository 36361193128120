import Vue from 'vue'
import Vuex from 'vuex'
import CONSTANTS from '@/constants.js';
import axios from 'axios'
import router from '@/router';

import snapsheet from '@/store/modules/snapsheet.js'
import customer from '@/store/modules/customer.js'
import screenLogo from '@/store/modules/screenLogo.js'
import actionsPermissions from '@/store/modules/actionsPermissions.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    colors: [],
    color_1: '',
    color_2: '',
    color_3: '',
    endpoint: process.env.VUE_APP_ENDPOINT,
    //dealerMode: true,
    layouts: [ //todo from api for each prod
      { name: "Center Front", width: "20%", height: "20%", x: "30%", y: "30%" },
      { name: "Upper Front", width: "20%", height: "20%", x: "30%", y: "20%" },
      { name: "Left Chest", width: "20%", height: "20%", x: "30%", y: "10%" },
    ],
    fonts: [
      {
        label: 'Full Block',
        id: 1,
        file: 'block'
      },
      {
        label: 'Apache',
        id: 2,
        file: 'apache'
      },
      {
        label: 'Collegiate',
        id: 3,
        file: 'collegiate'
      },
      {
        label: 'Falcon',
        id: 4,
        file: 'falcon'
      },
      {
        label: 'Racer',
        id: 5,
        file: 'racer'
      },
      {
        label: 'Warrior 1',
        id: 6,
        file: 'warrior_1'
      },
      {
        label: 'University',
        id: 7,
        file: 'university'
      }
    ],
    dealer: {
      api_token: null,
      email: '',
      fsg_code: '',
      id: '',
      logo: '',
      name: '',
      phone: '',
      rhino_code: '',
      settings: {}
    },
    rep: {},
    product: JSON.parse(JSON.stringify(CONSTANTS.DEFAULT_PRODUCT_DATA)),
    gender: "men",
    loading: false,
    eventLoading: false,
    error: false,
    settings: {
      endpoint: process.env.VUE_APP_ENDPOINT,
      token: null
    },
  },
  modules: {
    snapsheet,
    customer,
    screenLogo,
    actionsPermissions
  },
  getters: {
    dealerMode: (state) => {
      return state.dealer.api_token != null && state.dealer.api_token.length ? true : false;
    },
    activeProduct: (state) => {
      return state.product;
    },
    getColorByField: (state, getters) => (data) => {
      let color_id = getters.activeProduct[data.field];
      for (let index = 0; index < state.colors.length; index++) {
        const element = state.colors[index];
        if (element.id === color_id) {
          return element;
        }
      }
      return null;
    },
    logoIsPlaceholder: (_state, getters) => (data) => {
      let reversibleActive = !data.alternative ? getters.activeProduct.reversibleActive : !getters.activeProduct.reversibleActive;
      let sideKey = reversibleActive ? "reversible_" + data.side : data.side;
      return getters.activeProduct[sideKey].logo.url.length && !getters.activeProduct[sideKey].logo.original_url.length;
    },
    getColorById: (state) => (id) => {
      for (let index = 0; index < state.colors.length; index++) {
        const element = state.colors[index];
        if (element.id === id) {
          return element;
        }
      }
      return false;
    },
    getArtwork: (_state, getters) => (data) => {
      return getters.activeProduct[data.side].artwork;
    },
    getColorHexByField: (state, getters) => (data) => {
      let side = getters.activeProduct.reversibleActive ? 'reversible_' + data.side : data.side;
      let color_id = data.isArtwork
        ? getters.activeProduct[side][data.field]
        : !data.side || !data.subfield
          ? getters.activeProduct.reversibleActive
            ? !data.alternative
              ? getters.activeProduct['reversible_' + data.field]
              : getters.activeProduct[data.field]
            : !data.alternative
              ? getters.activeProduct[data.field]
              : getters.activeProduct['reversible_' + data.field]
          : getters.activeProduct[side][data.field][data.subfield];
      for (let index = 0; index < state.colors.length; index++) {
        const element = state.colors[index];
        if (element.id === color_id) {
          return element.hex;
        }
      }
      return '#FFFFFF'; //White placeholder when data still didn't come from the API
    },

    doesSessionDataBelongsToDealer: () => (sessionData) => {
      return sessionData.rhino_code.toLowerCase() === router.currentRoute.params.dealer_code.toLowerCase()

    }
  },
  actions: {
    async dealerLogin({ dispatch }, { passcode }) {
      try {
        dispatch('triggerEventStartLoading');
        await dispatch('getDealer', passcode)

      } catch (error) {
        console.log(error);
        dispatch("triggerError");
      } finally {
        dispatch('triggerEventEndLoading')
      }
    },

    dealerLogout({ commit }) {
      sessionStorage.removeItem(CONSTANTS.GAMEPLAN_SESSION)
      commit("setDealerMode", null);
    },

    triggerStartLoading({ commit }) {
      commit('changeLoadingState', true)
    },

    triggerEndLoading({ commit }) {
      commit('changeLoadingState', false)
    },

    triggerEventStartLoading({ commit }) {
      commit('changeEventLoadingState', true)
    },

    triggerEventEndLoading({ commit }) {
      commit('changeEventLoadingState', false)
    },

    triggerError({ commit }) {
      commit('changeErrorState')
    },

    resetError({ commit }) {
      commit('resetErrorState')
    },

    setRep({ commit }, data) {
      commit('setRep', data)
    },

    async fetchColors({ state, dispatch, commit, }) {
      try {
        const { data } = await axios.get(state.endpoint + 'api/snapsheets/init')

        commit('setColors', data)
      } catch (error) {
        dispatch('triggerError')
      }
    },

    async getDealer({ state, dispatch, commit }, passcode) {

      //Check if the session existes in the Storage Session, and if it belongs to the snapsheet's dealer
      if (await dispatch('checkIfSessionExists')) {
        const sessionData = await dispatch('getSessionData')
        commit("setDealer", sessionData);
        commit("setDealerMode", sessionData.api_token);
      } else {
        //Else, login with the url or login modal params
        try {
          const { data } = await axios
            .get(state.endpoint + "api/snapsheets/dealer", {
              params: {
                passcode: passcode || router.currentRoute.query.passcode,
                code: router.currentRoute.params.dealer_code,
                project_id: Number(router.currentRoute.params.snapsheet_id),
                project_type: "snapsheet"
              },
            })

          data['rhino_code'] = router.currentRoute.params.dealer_code
          commit("setDealer", data);

          const token = data.api_token

          //set session storage
          if (token) {
            await dispatch('saveSessionData', data)
          }

          commit("setDealerMode", token);

        } catch (error) {
          console.log(error);
          this._vm.$toastr.e("Error loading dealer data");
        }
      }
    },

    async initApplication({ getters, dispatch }) {
      try {
        dispatch('triggerStartLoading')
        await dispatch('getDealer')
        await dispatch('fetchColors')
        await dispatch('snapsheet/initSnapsheet')
        await dispatch('lockerModule/getLockerByCode', getters['snapsheet/lockerCode'])
      } catch (error) {
        console.log(error);
        this._vm.$toastr.e("Error trying to init the application");
        
        console.log(error);
      } finally {
        dispatch('triggerEndLoading')
      }

    },

    async checkIfSessionExists({ getters, dispatch }) {

      if (sessionStorage.getItem(CONSTANTS.GAMEPLAN_SESSION)) {
        const sessionData = await dispatch('getSessionData')
        return getters['doesSessionDataBelongsToDealer'](sessionData)
      } else {
        return false
      }
    },

    async getSessionData() {
      try {
        return await JSON.parse(sessionStorage.getItem(CONSTANTS.GAMEPLAN_SESSION))
      } catch (e) {
        console.log(e);
        throw new Error(e)
      }
    },

    async saveSessionData(_, sessionData) {

      try {
        const sessionDataStringified = await JSON.stringify(sessionData)
        sessionStorage.setItem(CONSTANTS.GAMEPLAN_SESSION, sessionDataStringified)

      } catch (e) {
        console.log(e);
        throw new Error(e)
      }

    },

  },
  mutations: {
    setDealer: (state, data) => {
      Vue.set(state, 'dealer', data)
      // state.id = data.id
      // state.dealer.email = data.email
      // state.dealer.logo = data.logo
      // state.dealer.phone = data.phone
      // state.dealer.header_color = data.header_color;
      // state.dealer.header_message = data.header_message;
      // state.rhino_code = data.rhino_code
      // state.dealer.api_token = data.api_token; //sets dealer mode if we have the passcode via GET
    },
    setDealerMode: (state, token) => {
      state.dealer.api_token = token;
      state.settings.token = token;
    },
    setRep: (state, data) => state.rep = data,
    setColors: (state, colors) => state.colors = colors,
    changeLoadingState: (state, bool) => state.loading = bool,
    changeEventLoadingState: (state, bool) => state.eventLoading = bool,
    changeErrorState: (state) => state.error = true,
    resetErrorState: (state) => state.error = false,
  },
})
