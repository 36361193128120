const EMPTY_SVG_GROUPS = { group1: [], group2: [], group3: [] };
const BACKUP_STOCK_COLOR_IDS = [25, 23, 24];
const LAYER = {
  SIDES: ["front", "side", "back"],
  MAIN_SIDES: ["front", "side"], //sets team name front for most of jerseys and side for masks
  FIELDS: ["name", "number", "logo"], //artwork?
  MAIN_FIELDS: ["name", "number"],
};

const DEFAULT = {
  FILL: "#FFFFFF",
  OUTLINE: "#111111",
  FONT: "block",
  FILL_ID: 25,
  OUTLINE_ID: 23,
  C1: 19,
  C2: 13,
  C3: 25,
};

const DEFAULT_PRODUCT_DATA = {
  color_1: DEFAULT.C1,
  color_2: DEFAULT.C2,
  color_3: DEFAULT.C3,
  layout: {},
  design: {},
  product: {},
  front: {
    artwork: 0,
    artwork_color_1: DEFAULT.C1,
    artwork_color_2: DEFAULT.C2,
    artwork_color_3: DEFAULT.C3,
    name: {
      value: 'Team name',
      fill: DEFAULT.FILL_ID,
      outline: DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"Team Name","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      size: 3,
    },
    number: {
      value: '00',
      fill: DEFAULT.FILL_ID,
      outline: DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"00","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      size: 3,
    },
    logo: {
      url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"YOUR LOGO","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      original_url: '',
    }
  },
  back: {
    artwork: 0,
    artwork_color_1: DEFAULT.C1,
    artwork_color_2: DEFAULT.C2,
    artwork_color_3: DEFAULT.C3,
    name: {
      value: 'Player name',
      fill: DEFAULT.FILL_ID,
      outline: DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"Player Name","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      size: 3,
    },
    number: {
      value: '00',
      fill: DEFAULT.FILL_ID,
      outline: DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"00","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      size: 3,
    },
    logo: {
      url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"YOUR LOGO","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      original_url: '',
    }
  },
  side: {
    artwork: 0,
    artwork_color_1: DEFAULT.C1,
    artwork_color_2: DEFAULT.C2,
    artwork_color_3: DEFAULT.C3,
    name: {
      value: 'Team name',
      fill: DEFAULT.FILL_ID,
      outline: DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://render-cdn.builder-api.foundersportgroup.com/api/builder/custom-text?json={"text":"Team name","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
    },
    number: {
      value: '00',
      fill: DEFAULT.FILL_ID,
      outline: DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://render-cdn.builder-api.foundersportgroup.com/api/builder/custom-text?json={"text":"00","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
    },
    logo: {
      url: '',
      original_url: '',
    }
  },
};

const DEFAULT_SCREEN_LOGO_PROPERTIES = {
  position: 2,
  zoom: 4
}

const PROJECT_STATUS = {
  OMG_STATUS: 'OMG Store Requested',
  QUOTE_STATUS: 'quote',
  CLOSED_STATUS: 'closed',
  OPEN_STATUS: 'open'
}

const DEFAULT_GRID_LAYOUTS_IDS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const GAMEPLAN_SESSION = 'gameplan-session'

export default {
  EMPTY_SVG_GROUPS,
  BACKUP_STOCK_COLOR_IDS,
  GAMEPLAN_SESSION,
  LAYER,
  DEFAULT,
  DEFAULT_PRODUCT_DATA,
  DEFAULT_GRID_LAYOUTS_IDS,
  DEFAULT_SCREEN_LOGO_PROPERTIES,
  PROJECT_STATUS
}