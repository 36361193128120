<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 864 864"
    :x="artwork_properties.x"
    :y="artwork_properties.y"
    :width="artwork_properties.width"
    :height="artwork_properties.height"
  >
    <defs />
    <g>
      <path
        v-for="(path, index) in group_1"
        :key="index"
        :d="path"
        v-bind:style="{ fill: artwork_color_1 }"
      />
      <polygon
        v-for="(polygon, index) in group_1_polygons"
        :key="index + '_polygon1'"
        :points="polygon"
        v-bind:style="{ fill: artwork_color_1 }"
      />
      <rect
        v-for="(rect, index) in group_1_rects"
        :key="index + '_rect1'"
        :x="rect.x"
        :y="rect.y"
        :width="rect.width"
        :height="rect.height"
        v-bind:style="{ fill: artwork_color_1 }"
      />
    </g>
    <g>
      <path
        v-for="(path, index) in group_2"
        :key="index"
        :d="path"
        v-bind:style="{ fill: artwork_color_2 }"
      />
      <polygon
        v-for="(polygon, index) in group_2_polygons"
        :key="index + '_polygon2'"
        :points="polygon"
        v-bind:style="{ fill: artwork_color_2 }"
      />
      <rect
        v-for="(rect, index) in group_2_rects"
        :key="index + '_rect2'"
        :x="rect.x"
        :y="rect.y"
        :width="rect.width"
        :height="rect.height"
        v-bind:style="{ fill: artwork_color_2 }"
      />
    </g>
    <g>
      <path
        v-for="(path, index) in group_3"
        :key="index"
        :d="path"
        v-bind:style="{ fill: artwork_color_3 }"
      />
      <polygon
        v-for="(polygon, index) in group_3_polygons"
        :key="index + '_polygon3'"
        :points="polygon"
        v-bind:style="{ fill: artwork_color_3 }"
      />
      <rect
        v-for="(rect, index) in group_3_rects"
        :key="index + '_rect3'"
        :x="rect.x"
        :y="rect.y"
        :width="rect.width"
        :height="rect.height"
        v-bind:style="{ fill: artwork_color_3 }"
      />
    </g>
    <image v-show="name_visible" class="svg-name" :width="artwork_name_properties ? artwork_name_properties.width : '0%'" :x="artwork_name_properties ? artwork_name_properties.x : '0%'" :y="artwork_name_properties ? artwork_name_properties.y : '0%'" :height="artwork_name_properties ? artwork_name_properties.height : '0%'" :xlink:href="stamp_name" v-bind:class="{ loading: text_name_loading }"></image>
  </svg>
</template>

<script>
const default_properties = {
  width: "34%",
  height: "24%",
  x: "33.5%",
  y: "31.5%",
};

import { mapGetters } from "vuex";

export default {
  name: "VectorArtwork",
  props: ["uniform_data", "product_type", "artwork", "side"],
  computed: {
    ...mapGetters(["getColorHexByField", "getColorById"]),
    name_visible() {
      return this.activeProduct[this.side].name !=
            null &&
            this.activeProduct[this.side].name
              .visible
    },
    stamp_name() {
      return this.activeProduct[this.side].name.stamp_url;
    },
    group_1() {
      return this.artwork_svg ? this.artwork_svg.group1 : false;
    },
    group_2() {
      return this.artwork_svg ? this.artwork_svg.group2 : false;
    },
    group_3() {
      return this.artwork_svg ? this.artwork_svg.group3 : false;
    },
    group_1_polygons() {
      return this.artwork_svg ? this.artwork_svg.group1_polygons : false;
    },
    group_2_polygons() {
      return this.artwork_svg ? this.artwork_svg.group2_polygons : false;
    },
    group_3_polygons() {
      return this.artwork_svg ? this.artwork_svg.group3_polygons : false;
    },
    group_1_rects() {
      return this.artwork_svg ? this.artwork_svg.group1_rects : false;
    },
    group_2_rects() {
      return this.artwork_svg ? this.artwork_svg.group2_rects : false;
    },
    group_3_rects() {
      return this.artwork_svg ? this.artwork_svg.group3_rects : false;
    },
    artwork_svg() {
      return this.artwork && this.artwork.svg ? JSON.parse(this.artwork.svg) : [];
    },
    artwork_color_1() {
      return this.getColorById(
        this.activeProduct[this.side].artwork_color_1
      ).hex;
    },
    artwork_color_2() {
      return this.getColorById(
        this.activeProduct[this.side].artwork_color_2
      ).hex;
    },
    artwork_color_3() {
      return this.getColorById(
        this.activeProduct[this.side].artwork_color_3
      ).hex;
    },
    artwork_properties() {
      return (
        this.artwork.layout_attributes ? this.artwork.layout_attributes[this.side].artwork || default_properties : false
      );
    },
    artwork_name_properties() {
      return (
        this.artwork.layout_attributes ? this.artwork.layout_attributes[this.side].name || default_properties : false
      );
    },
  },
  data() {
    return {
      text_name_loading: false,
    }
  }
};
</script>