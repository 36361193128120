import CONSTANTS from "@/constants.js";
const { PROJECT_STATUS } = CONSTANTS
const { QUOTE_STATUS, OMG_STATUS, CLOSED_STATUS } = PROJECT_STATUS

const actionsPermissions = {
  namespaced: true,
  getters: {
    isAllowedDealerOnlyAction: (_state, _getters, _rootState, rootGetters) => {
      return rootGetters.dealerMode ? true : false
    },
    isProjectLocked: (_state, _getters, _rootState, rootGetters) => {
      const projectStatus = rootGetters['snapsheet/getSnapsheetStatus'] || ''

      //Locked if the dealer mode is off and the project is on quote, omg or closed status, or is just a static template
      return rootGetters['snapsheet/isStaticTemplate']
        || projectStatus?.toLowerCase() === QUOTE_STATUS?.toLowerCase()
        || projectStatus?.toLowerCase() === OMG_STATUS?.toLowerCase()
        || projectStatus?.toLowerCase() === CLOSED_STATUS?.toLowerCase()
    },

    isProjectUnlockeable(_state, _getters, _rootState, rootGetters) {
      const projectStatus = rootGetters['snapsheet/getSnapsheetStatus'] || ''
      return rootGetters.dealerMode && projectStatus?.toLowerCase() === QUOTE_STATUS?.toLowerCase()
    }
  }
}

export default actionsPermissions

