<template>
  <div id="app">
    <transition name="fade">
      <div id="loader-app-container" v-if="loading">
        <Loader />
      </div>
    </transition>

    <template v-if="!loading">
      <DealerHeader
        v-if="!isStaticTemplate"
        :lockerRoute="lockerRoute"
        :homepageRoute="homepageRoute"
        :lockerProductsLength="lockerProductsLength"
        :dealerData="dealer"
        :repData="rep"
        :isProjectLocked="isProjectLocked"
        :isProjectUnlockeable="isProjectUnlockeable"
        :dealerMode="dealerMode"
      />
      <router-view />
      <Footer
        v-on="{ openDealerLogin, dealerLogout, closeDealerLogin }"
        :dealerMode="dealerMode"
      />

      <DealerLoginModal
        v-if="showLoginModal"
        v-on="{ successLogin, closeModal }"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import DealerHeader from "@/components/DealerHeader.vue";
import Footer from "@/components/Footer.vue";
import DealerLoginModal from "@/components/DealerLoginModal.vue";
import Loader from "./components/Loader.vue";

export default {
  name: "App",

  components: {
    DealerHeader,
    Footer,
    DealerLoginModal,
    Loader,
  },

  data() {
    return {
      showLoginModal: false,
    };
  },

  computed: {
    ...mapState(["dealer", "rep", "lockerModule", "loading"]),
    ...mapGetters(["dealerMode"]),
    ...mapGetters("snapsheet", ["isStaticTemplate"]),
    ...mapGetters("actionsPermissions", [
      "isProjectLocked",
      "isProjectUnlockeable",
    ]),

    lockerRoute() {
      // return `snapsheets/:dealer_code/:snapsheet_id/locker/:locker_code`
      const { dealer_code, snapsheet_id } = this.$route.params;
      return `/snapsheets/${dealer_code}/${snapsheet_id}/locker`;
    },

    homepageRoute() {
      // return `snapsheets/:dealer_code/:snapsheet_id/locker/:locker_code`
      const { dealer_code, snapsheet_id } = this.$route.params;
      return `/snapsheets/${dealer_code}/${snapsheet_id}/`;
    },

    lockerProductsLength() {
      return this.lockerModule.locker.products.length;
    },
  },

  methods: {
    ...mapActions(["initApplication"]),
    ...mapActions("snapsheet", ["calculatePages"]),

    openDealerLogin() {
      this.showLoginModal = true;
    },
    closeDealerLogin() {
      this.showLoginModal = false;
      //scroll to top?
    },
    dealerLogout() {
      this.showLoginModal = false;
    },
    successLogin() {
      this.showLoginModal = false;
    },
    closeModal() {
      this.showLoginModal = false;
    },
  },

  mounted() {
    this.$toastr.defaultStyle = { 'font-family': "SlateStd" };
    
    if (!this.$route.params.dealer_code || !this.$route.params.snapsheet_id) {
      this.$router.push("404");
    } else {
      this.initApplication().then(() => {
        let query = Object.assign({}, this.$route.query);
        if (query.passcode) {
          delete query.passcode;
          this.$router.replace({ query });
        }
      });
    }
  },

};
</script>


<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

$font-dir: "/fonts/";

@font-face {
  font-family: "Forza";
  src: url("#{$font-dir}Forza-Medium.otf");
}
@font-face {
  font-family: "ForzaBold";
  src: url("#{$font-dir}Forza-Bold.otf");
}
@font-face {
  font-family: "SlateStd";
  src: url("#{$font-dir}SlateStd.otf");
}
@font-face {
  font-family: "SlateStd-Bold";
  src: url("#{$font-dir}SlateStd-Bold.otf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .btn {
    font-family: "ForzaBold";
  }
  div,
  p,
  span,
  a,
  label,
  input,
  textarea,
  select,
  button {
    font-family: "SlateStd";
  }

  button {
    border: none;
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      transform: scale(1.05);
    }
  }

  strong {
    font-family: "SlateStd-Bold";
  }

  .text-center {
    text-align: center;
  }

  .full-width {
    width: 100%;
  }

  .m-auto {
    margin: auto;
  }

  .d-flex {
    display: flex;
  }

  .align-items-center {
    align-items: center;
  }
  .justify-center {
    justify-content: center;
  }

  .fade-in {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

#loader-app-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100vh;
  width: 100vw;
}

.has-been-sent-message {
  text-align: center;
}
</style>
