<template>
  <div class="product-image fade-in" v-if="shadow !== 'test'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 800 1000"
      style="enable-background: new 0 0 800 1000"
      xml:space="preserve"
    >
      <g>
        <path
          v-for="(path, index) in group_1"
          :key="index"
          :d="path"
          v-bind:style="{ fill: color_1 }"
        ></path>
      </g>

      <g>
        <path
          v-for="(path, index) in group_2"
          :key="index"
          :d="path"
          v-bind:style="{ fill: color_2 }"
        ></path>
      </g>

      <g>
        <path
          v-for="(path, index) in group_3"
          :key="index"
          :d="path"
          v-bind:style="{ fill: color_3 }"
        ></path>
      </g>
      <!-- Todo component for images -->
      <image
        v-if="shadow && isVariantStock"
        width="800"
        height="1000"
        class="svg-background"
        :xlink:href="shadow"
      ></image>
      <image
        v-if="stamp_logo"
        v-show="stamp_logo"
        preserveAspectRatio="xMidYMin"
        class="svg-logo"
        :width="stamp_logo_properties ? stamp_logo_properties.width : '0%'"
        :x="stamp_logo_properties ? stamp_logo_properties.x : '0%'"
        :y="stamp_logo_properties ? stamp_logo_properties.y : '0%'"
        :height="stamp_logo_properties ? stamp_logo_properties.height : '0%'"
        :xlink:href="stamp_logo"
      ></image>
      <image
        v-if="shadow && !isVariantStock"
        width="800"
        height="1000"
        class="svg-background"
        :xlink:href="shadow"
      ></image>
      <VectorArtwork
        v-if="hasArtwork"
        :uniform_data="uniform_data"
        :artwork="artwork"
        :side="side"
      ></VectorArtwork>
    </svg>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import VectorArtwork from "@/components/VectorArtwork.vue";

export default {
  name: "Vector",
  props: [
    "svgs",
    "backgrounds",
    "side",
    "layout",
    "is_stock",
    "uniform_data",
    "uniform_info",
    "stock_color",
    "isVariantStock",
    "stamp_logo",
  ],

  components: {
    VectorArtwork,
  },

  computed: {
    ...mapState(["settings", "gender"]),
    ...mapGetters([
      "getColorHexByField",
      "logoIsPlaceholder",
      "getColorById",
      "getArtwork",
      "activeProduct",
    ]),
    artwork() {
      if (!this.hasArtwork || !this.artworks) return false;
      if (!this.activeArtworkId) {
        return this.artworks[0];
      }
      for (let index = 0; index < this.artworks.length; index++) {
        const art = this.artworks[index];
        if (art.id === this.activeArtworkId) {
          return art;
        }
      }
      return false;
    },
    activeArtworkId() {
      return this.getArtwork({
        product: "main_product",
        side: this.sideKey,
      });
    },
    hasArtwork() {
      if (!this.layout) return false;
      if (
        !this.layout.layout_attributes ||
        !this.layout.layout_attributes[this.side]
      )
        return false;
      return (
        this.layout.layout_attributes[this.side] &&
        this.layout.layout_attributes[this.side].artwork
      );
    },
    artworks() {
      return this.layout.artworks;
    },
    color_1() {
      if (this.is_stock) {
        return this.stock_color;
      }
      return this.getColorHexByField({
        field: "color_1",
        product_type: this.product_type,
      });
    },
    color_2() {
      return this.getColorHexByField({
        field: "color_2",
        product_type: this.product_type,
      });
    },
    color_3() {
      return this.getColorHexByField({
        field: "color_3",
        product_type: this.product_type,
      });
    },

    stamp_logo_properties() {
      return this.layout ? this.layout.layout_attributes.front.logo : null;
    },
    group_1() {
      return this.svgs && this.svgs.group1 ? this.svgs.group1 : "";
    },
    group_2() {
      return this.svgs && this.svgs.group2 ? this.svgs.group2 : "";
    },
    group_3() {
      return this.svgs && this.svgs.group3 ? this.svgs.group3 : "";
    },
    shadow() {
      /*if (this.backgrounds) {
        let gender = this.gender;
        let key = "shadow_" + gender + "_" + this.side;
        if (this.backgrounds[key]) {
          if (this.backgrounds[key].indexOf(".com") !== -1) {
            return this.backgrounds[key]; //returns api shadow
          }
          return this.settings.endpoint + this.backgrounds[key];
        }
      }
      return false;
      */
      if (this.backgrounds) {
        return this.backgrounds[Object.keys(this.backgrounds)[2]];
        //un chanchullo para que traiga el primero
        //hay que ver si vamos a tener alguna regla de generos para
        //saber cual imagen usar
      }
      return false;
    },
    name_allowed() {
      if (!this.layout || !this.layout.layout_attributes) return false;
      return (
        this.layout.layout_attributes[this.side] != null &&
        this.layout.layout_attributes[this.side].name != null
      );
    },
    number_allowed() {
      if (!this.layout || !this.layout.layout_attributes) return false;
      return (
        this.layout.layout_attributes[this.side] != null &&
        this.layout.layout_attributes[this.side].number != null
      );
    },
    logo_allowed() {
      if (!this.layout || !this.layout.layout_attributes) return false;
      return this.layout && this.layout.layout_attributes && !this.uniform_data
        ? (this.layout.layout_attributes[this.side] != null &&
            Object.keys(this.activeProduct.layout).length > 0 &&
            this.activeProduct.layout.layout_attributes[this.side].logo !=
              null) ||
            (this.layout.layout_attributes[this.side] &&
              this.layout.layout_attributes[this.side].logo &&
              Object.keys(this.layout.layout_attributes[this.side].logo)
                .length > 0)
        : false;
    },
    name_visible() {
      return (
        this.activeProduct[this.sideKey].name != null &&
        this.activeProduct[this.sideKey].name.visible
      );
    },
    number_visible() {
      return (
        this.activeProduct[this.sideKey].number != null &&
        this.activeProduct[this.sideKey].number.visible
      );
    },
  },
  data() {
    return {
      text_name_loading: false,
      text_number_loading: false,
      text_logo_loading: false,
      sideKey: "front",
    };
  },
  methods: {
    ...mapGetters(["getTeamNameUrl"]),
    getArtworkById(id) {
      //computed?
      for (let index = 0; index < this.artworks.length; index++) {
        const element = this.artworks[index];
        if (element.id === id) {
          return element;
        }
      }
      return false;
    },
    setStampValues(prop, field, _default) {
      let val = _default;
      if (this.activeProduct[this.sideKey][field].stamp_url.length) {
        val = this.activeProduct[this.sideKey][field].stamp_url;
      } else {
        if (this.svgs !== undefined) {
          val = this.svgs[field];
        }
      }
      this[prop] = val;
    },
  },
};
</script>

<style lang="scss">
/* svg {
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.3));
} */
</style>