<template>
  <button
    class="add-to-locker-button btn"
    :disabled="isLockerLoading || isProjectLocked"
    :class="{
      disabled: isLockerLoading || isProjectLocked,
      'dealer-mode': dealerMode,
    }"
    @click="$emit('addToLocker')"
  >
    Add to locker
  </button>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AddToLockerButton",
  props: ["isLockerLoading", "isProjectLocked"],
  computed: {
    ...mapGetters(["dealerMode"]),
  },
};
</script>

<style>
</style>