<template>
  <Modal :isModalOpen="isModalOpen" @onCloseModal="$emit('cancel')">
    <article id="confirm-box">
      <h1>
        {{ title }}
      </h1>
      <div class="buttons-container">
        <button class="btn cancel" @click="$emit('cancel')">
          {{ cancelText || "Cancel" }}
        </button>
        <button class="btn confirm" @click="$emit('confirm')">
          {{ confirmationText || "Ok" }}
        </button>
      </div>
    </article>
  </Modal>
</template>

<script>
import Modal from "@/components/Utils/Modal.vue";
export default {
  name: "ModalConfirmation",
  props: ["isModalOpen", "title", "confirmationText", "cancelText"],
  components: { Modal },
};
</script>

<style lang="scss" scoped>
#confirm-box {
  padding: 3% 2%;
  max-width: 60%;
  background: white;
  border-radius: 0.5rem;
  margin: auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);

  h1 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
    font-family: "SlateStd", Helvetica, sans-serif;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    max-width: 50%;
    margin: auto;
    .btn {
      padding: .5rem;

      &.confirm {
        background: #4caf50;
        color: white;
        &:hover {
          background: #449e47;
        }
      }
      &.cancel {
        background: #f44336;
        color: white;
        &:hover {
          background: #d83b30;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }
}
</style>