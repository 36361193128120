<template>
  <li
    class="result-card"
    :class="{ inactive: productIsDuplicated(product.id) }"
    @click="handleSelectProduct"
  >
    <p>{{ product.alias }}</p>
    <p>{{ product.name }}</p>
    <p v-if="productIsDuplicated(product.id)">ALREADY FEATURED</p>
  </li>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "axios";
export default {
  name: "SearchResultsItem",

  props: {
    product: {
      type: Object,
    },
    order: {
      type: Number,
    },
  },

  computed: {
    ...mapState(["endpoint", "dealer"]),
    ...mapState("snapsheet", ["snapsheetId", "editableProduct"]),

    ...mapGetters("snapsheet", ["productIsDuplicated"]),

    svgs() {
      return this.product.design
        ? this.product.design
        : { group1: [], group2: [], group3: [] };
    },
    backgrounds() {
      return this.svgs.backgrounds;
    },
  },

  methods: {

    ...mapActions("snapsheet", ["setEditableProduct"]),

    handleSelectProduct() {
      if (!this.productIsDuplicated(this.product.id)) {
        //request full product info then seteditableproduct and emit onselectedproduct
        const snapsheetId = this.snapsheetId;
        const url =
          this.endpoint + `api/snapsheets/action/pick-product/${snapsheetId}`;

        axios
          .get(url, {
            params: {
              product_id: this.product.id,
              order: this.order,
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.dealer.api_token,
            },
          })
          .then((response) => {
            this.setEditableProduct({
              product: response.data,
              order: this.order,
              logoId: this.editableProduct?.logoId || null,
              screenLogo: this.editableProduct.screenLogo,
            });
          })
          .catch((error) => {
            this.$toastr.e("Error selecting product");
            console.log(error);
          })
          .finally(() => {
            this.$emit("onSelectedProduct");
          });
      }
    },
  },
};
</script>
