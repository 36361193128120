<template>
  <main class="home">
    <div v-if="loading" class="page-loader">
      <Loader />
    </div>

    <template v-else>
      <PagesContainer :type="'snapsheet'" :pages="pages">
        <template v-slot:default="slotProps">
          <SnapsheetPage
            :productsInPage="slotProps.page"
            :pageId="slotProps.pageId"
            @openPreviewNewLockerProducts="handleOpenPreviewNewLockerProducts"
          />
        </template>

        <template v-slot:hidding-modal>
          <transition name="fade">
            <div
              v-if="isEditModeActive"
              class="hidding-modal"
              @click="() => changeEditMode(false)"
            ></div>
          </transition>
        </template>
      </PagesContainer>

      <NewLockerProductsPreviewModal
        :isModalOpen="isLockerPreviewModalOpen"
        :lockerProductsIds="previewLockerProductsIds"
        @onCloseModal="isLockerPreviewModalOpen = false"
        @redirectToLocker="redirectToLocker"
      />
    </template>
  </main>
</template>

<script>
import PagesContainer from "@/components/PagesContainer.vue";
import SnapsheetPage from "@/components/Snapsheet/SnapsheetPage.vue";

import Loader from "@/components/Loader.vue";

import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "Snapsheet",
  components: {
    PagesContainer,
    SnapsheetPage,
    Loader,
  },

  data() {
    return {
      showLoginModal: false,
      isLockerPreviewModalOpen: false,
      quantityOfLockerProductsPreviewToDisplay: 1, // This could be 2 or 3 (For Instant Playbooks)
    };
  },

  computed: {
    ...mapGetters(["dealerMode"]),
    ...mapState(["loading", "rep"]),

    ...mapState("snapsheet", [
      "color_1",
      "customerId",
      "isEditModeActive",
      "pages",
      "projectTitle",
      "repId",
    ]),

    ...mapState("customer", ["logos"]),

    ...mapState(["lockerModule"]),

    previewLockerProductsIds() {
      const products = this.lockerModule.locker.products.slice(
        -Math.abs(this.quantityOfLockerProductsPreviewToDisplay)
      ); //Display the last 1, 2 or 3 elements of the locker

      return products.map((pr) => pr.lockerProductId);
    },
  },

  methods: {
    ...mapActions("snapsheet", [
      "initSnapsheet",
      "changeEditMode",
      "updateSnapsheetCustomerId",
      "calculatePages",
    ]),
    ...mapActions(["init"]),
    ...mapActions("customer", ["initCustomer", "createCustomer"]),
    ...mapActions(["triggerStartLoading", "triggerEndLoading"]),

    handleOpenPreviewNewLockerProducts() {
      this.isLockerPreviewModalOpen = true;
    },

    redirectToLocker() {
      const { dealer_code, snapsheet_id } = this.$route.params;
      this.$router.push(`/snapsheets/${dealer_code}/${snapsheet_id}/locker`);
    },
  },
  watch: {
    dealerMode() {
      this.calculatePages();
    },
  },
};
</script>
<style lang="scss">
#app {
  .home {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    position: relative;
    .page-loader {
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.15s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }

    .hidding-modal {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #00000069;
      z-index: 1;
    }

    .dealer-menu-button {
      background-color: #fffcd8;
      color: #000;
      text-transform: uppercase;
      width: 180px;
      padding: 7px;
      cursor: pointer;
      border: none;
      -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35);
      -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35);
    }
  }
}
</style>