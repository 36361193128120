<template>
  <div
    class="color-option fade-in"
    :class="{ 'color-option-hover': !productChanged }"
    @click="selectColorVariant()"
  >
    <Vector
      :title="designName"
      :side="'front'"
      :svgs="svgs"
      :backgrounds="backgrounds"
      :layout="product.layout"
      :is_stock="!product.is_sublimable"
      :isVariantStock="!product.is_sublimable"
    />
    <p>{{ abbr_name }}</p>
  </div>
</template>

<script>
const loadingTime = 75;
import Vector from "@/components/Vector.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ColorOption",
  props: ["design", "product", "index", "productChanged"],
  methods: {
    ...mapActions("snapsheet", ["updateProductVariantColor"]),

    async selectColorVariant() {
      if (!this.productChanged) {
        await this.updateProductVariantColor({
          selectedColorId: this.design.color_id,
          wrapperId: this.product.wrapperId,
          order: this.product.order,
        });
        // this.$emit("onColorSelected");
      }
    },
  },
  computed: {
    ...mapGetters(["dealerMode"]),
    abbr_name() {
      const indexOfUnderscore = this.design.color_name.trim().lastIndexOf("_");
      const firstLetter = this.design.color_name.trim().charAt(0);
      const secondLetter = this.design.color_name
        .trim()
        .charAt(indexOfUnderscore + 1);

      return `${firstLetter}${secondLetter}`;
    },
    designName() {
      return this.design.color_name.replace("_", " ").toUpperCase().trim(); //changes in sublimable
    },
    svgs() {
      return { group1: [], group2: [], group3: [] }; //will change with sublimables
    },
    backgrounds() {
      return this.design.backgrounds;
    },
  },
  components: {
    Vector,
  },
  mounted() {
    setTimeout(() => {
      this.isReady = true;
    }, loadingTime * this.index);
  },
  data() {
    return {
      isReady: false,
    };
  },
};
</script>

<style lang="scss">
#app {
  .color-option {
    width: 45px;
    margin: 1px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #fff;
    border-radius: 5px;
    svg {
      width: 20px;
      p {
        font-size: 0.7rem;
      }
    }
    &.color-option-hover {
      cursor: pointer;
      &:hover {
        border-color: #000;
      }
    }
  }
}
</style>