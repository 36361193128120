<template>
  <article
    class="grid-card"
    :class="{
      'edit-mode-active': isEditModeActive && isUniqEditMode,
      empty: productIsEmpty && dealerMode,
      'coach-mode': !dealerMode,
    }"
  >
    <!-- Editable Info -->
    <template
      v-if="!ShowAddButton && !isEditDecorationsActive && !isSearchModalOpen"
    >
      <SnapsheetPageItemInfo
        v-on="{
          handleSearchModal,
          handleEditPriceMode,
          handleSaveProduct,
          handleEditMode,
          handleEditDecorations,
          addToLocker: handleAddToLocker,
        }"
        @openPreviewNewLockerProducts="emitOpenPreviewNewLockerProducts"
        :editDecorationsEnabled="editDecorationsEnabled"
        :ShowSwapButton="ShowSwapButton"
        :showSaveChangesButton="showSaveChangesButton"
        :isUniqEditMode="isUniqEditMode"
        :isEditPriceModeActive="isEditPriceModeActive"
        :originalProduct="product"
        :editableProduct="editableProduct"
        :pageId="pageId"
        :arrayIndex="arrayIndex"
        :cardLoading="loading"
        :screenLogoImage="screenLogoImage"
        :layouts="layouts"
        :productChanged="productChanged"
      />
    </template>

    <!-- Decorations Info -->
    <template
      v-if="!ShowAddButton && isEditDecorationsActive && !isSearchModalOpen"
    >
      <SnapsheetPageItemDecorations
        :showSaveChangesButton="showSaveChangesButton"
        :isUniqEditMode="isUniqEditMode"
        :isEditPriceModeActive="isEditPriceModeActive"
        :originalProduct="product"
        :editableProduct="editableProduct"
        :pageId="pageId"
        :arrayIndex="arrayIndex"
        :cardLoading="loading"
        :originalLogo="originalLogo"
        :originalLogoImage="originalLogoImage"
        :screenLogoImage="screenLogoImage"
        :layouts="layouts"
      />
    </template>

    <!-- Search Bar -->
    <transition name="fade">
      <SearchProductsModal
        v-if="isSearchModalOpen"
        :isModalOpen="isSearchModalOpen"
        v-on="{ onCloseModal: handleSearchModal }"
        :order="product.order"
        :originalLogoImage="originalLogoImage"
        :screenLogoImage="screenLogoImage"
      />
    </transition>

    <!-- Add Product buttton -->
    <button
      v-if="ShowAddButton && dealerMode"
      class="add-button btn"
      @click="() => handleEditMode(true)"
    >
      Add Product
    </button>

    <!-- Save Changes button -->
    <button
      v-if="showSaveChangesButton"
      @click="handleSaveProduct"
      class="save-button"
    >
      Save changes
    </button>

    <!-- Dealer Menu Options on Hover -->
    <template
      v-if="!productIsEmpty && !isProjectLocked && dealerMode && !loading"
    >
      <div class="edit-options-container">
        <!-- Decorations button -->
        <button
          v-show="editDecorationsEnabled"
          class="edit-decorations-btn btn"
          :class="{ disabled: !editDecorationsEnabled }"
          @click="handleEditDecorations"
        >
          {{
            editDecorationsEnabled
              ? "Edit Decorations"
              : "This style does not use decorations"
          }}
        </button>

        <!-- Details button -->
        <button class="btn" @click="handleEditItemDetails" v-show="dealerMode">
          Edit Item Details
        </button>

        <!-- Delete Product -->
        <button
          v-if="dealerMode"
          class="btn delete"
          @click="openConfirmationModal"
        >
          <img src="/images/trash.png" alt="delete product" />
        </button>

        <AddToLockerButton
          :isLockerLoading="isLockerLoading"
          :isProjectLocked="isProjectLocked"
          :dealerMode="dealerMode"
          @addToLocker="handleAddToLocker"
        />
      </div>
    </template>

    <!-- Confirmation modal -->
    <ModalConfirmation
      :isModalOpen="isConfirmationModalOpen"
      :title="'Are you sure you want to delete this product?'"
      @confirm="handleDeleteProduct"
      @cancel="closeConfirmationModal"
    />
  </article>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import SearchProductsModal from "@/components/Snapsheet/SearchProductsModal.vue";
import SnapsheetPageItemInfo from "@/components/Snapsheet/SnapsheetPageItemInfo.vue";
import SnapsheetPageItemDecorations from "@/components/Snapsheet/SnapsheetPageItemDecorations.vue";
import AddToLockerButton from "@/components/Snapsheet/AddToLockerButton.vue";
import ModalConfirmation from "@/components/Utils/ModalConfirmation.vue";
export default {
  name: "SnapsheetPageItem",
  components: {
    SearchProductsModal,
    SnapsheetPageItemInfo,
    SnapsheetPageItemDecorations,
    AddToLockerButton,
    ModalConfirmation,
  },
  props: ["product", "pageId", "arrayIndex"],

  data() {
    return {
      isSearchModalOpen: false,
      isConfirmationModalOpen: false,
      isUniqEditMode: false, //To set as active grid item when the user is editing the product
      isEditPriceModeActive: false,
      isEditDecorationsActive: false,
      loading: false,
      productChanged: false,
    };
  },

  computed: {
    ...mapState("snapsheet", [
      "isEditModeActive",
      "editableProduct",
      "productHasBeenEdited",
      "decorationHasBeenEdited",
    ]),
    ...mapState(["lockerModule"]),
    ...mapGetters(["dealerMode"]),
    ...mapGetters("actionsPermissions", ["isProjectLocked"]),
    ...mapGetters("customer", ["getLogoById", "customerId"]),
    ...mapGetters("lockerModule", ["lockerCode"]),
    ...mapGetters("snapsheet", ["productIsDuplicated", "editableProductIsNew"]),

    isLockerLoading() {
      return this.lockerModule.loadingLocker;
    },

    editDecorationsEnabled() {
      return this.layouts?.length > 0 && !this.isProjectLocked;
    },

    layouts() {
      return this.product?.layouts;
    },

    index() {
      if (this.pageId == 1) {
        return this.arrayIndex;
      } else {
        return this.arrayIndex + 3;
      }
    },

    originalLogo() {
      //Return the original logo. If we are in edit mode, return the Editable Product original Logo, otherwise return the originalProduct logo
      return this.isEditModeActive && this.isUniqEditMode
        ? this.editableProduct && this.editableProduct.logoId
          ? this.getLogoById(this.editableProduct.logoId)
          : null
        : this.product && this.product.logoId
        ? this.getLogoById(this.product.logoId)
        : null;
    },

    originalLogoImage() {
      //Return the original logo. If we are in edit mode, return the Editable Product original Logo, otherwise return the originalProduct logo
      return this.originalLogo?.content || null;
    },

    screenLogoImage() {
      //Return the original logo. If we are in edit mode, return the Editable Producto Screen or originalLogoImage(support old snapsheets), otherwise return the originalProduct Screen or originalLogoImage(support old snapsheets)
      return this.isEditModeActive && this.isUniqEditMode
        ? this.editableProduct && this.editableProduct.screenLogo?.logo
          ? this.editableProduct.screenLogo?.logo || this.editableProduct.logo
          : null
        : this.product && this.product.screenLogo?.logo
        ? this.product.screenLogo?.logo || this.product.logo
        : null;
    },

    productIsEmpty() {
      return this.product == null || !this.product.id;
    },

    editableProductIsEmpty() {
      return !this.editableProduct.id;
    },

    ShowAddButton() {
      if (this.isEditModeActive) {
        return this.productIsEmpty && !this.isUniqEditMode;
      } else return this.productIsEmpty && this.editableProductIsEmpty;
    },
    ShowSwapButton() {
      return this.isEditModeActive && this.isUniqEditMode && this.dealerMode;
    },

    showSaveChangesButton() {
      return (
        !this.loading &&
        this.isEditModeActive &&
        this.isUniqEditMode &&
        (this.editableProductIsNew || this.productHasBeenEdited)
      );
    },
  },

  methods: {
    ...mapActions("snapsheet", [
      "changeEditMode",
      "setEditableProduct",
      "changeProductHasBeenEdited",
      "changeDecorationHasBeenEdited",
      "saveProduct",
      "deleteProduct",
      "addToLocker",
    ]),

    handleEditMode(triggerSearchModal = false) {
      //Active edit mode when the user just selected a product from the searchBarModal
      this.changeEditMode(true);
      this.isUniqEditMode = true;
      if (triggerSearchModal === true) {
        this.handleSearchModal();
      }
    },

    handleEditItemDetails() {
      this.productChanged = false;
      this.handleEditMode();
    },

    handleEditDecorations() {
      if (!this.editDecorationsEnabled) return false;
      this.changeEditMode(true);
      this.isUniqEditMode = true;
      this.isEditDecorationsActive = !this.isEditDecorationsActive;
      this.changeDecorationHasBeenEdited(false);
    },

    handleEditPriceMode() {
      this.isEditPriceModeActive = !this.isEditPriceModeActive;
    },

    handleSearchModal() {
      this.productChanged = true;
      this.isSearchModalOpen = !this.isSearchModalOpen;
    },

    async handleSetProduct() {
      // if (!this.productIsDuplicated(this.product.id)) {

      await this.saveProduct();
      this.$emit("onSelectedProduct");

      // }
    },

    async handleSaveProduct() {
      if (!this.loading) {
        this.loading = true;
        try {
          await this.handleSetProduct();
          this.changeEditMode(false);

          this.isUniqEditMode = false;
        } catch (error) {
          this.$toastr.e("Error saving changes, Code: " + error.status);
        } finally {
          this.loading = false;
        }
      }
    },

    async handleDeleteProduct() {
      await this.deleteProduct(this.product.order);
    },

    async handleAddToLocker() {
      this.loading = true;
      try {
        const { wrapperId, variantId } = this.product;
        await this.addToLocker({
          wrapperId,
          variantId,
          lockerCode: this.lockerCode,
        });

        this.wasAddedToLocker = true;
        this.emitOpenPreviewNewLockerProducts();
      } catch (error) {
        console.log(error);
        this.$toastr.e("Error trying to save the product in your locker");
      } finally {
        this.loading = false;
      }
    },

    emitOpenPreviewNewLockerProducts() {
      this.$emit("openPreviewNewLockerProducts");
      this.changeEditMode(false);
      this.isUniqEditMode = false;
    },

    openConfirmationModal() {
      this.isConfirmationModalOpen = true;
    },

    closeConfirmationModal() {
      this.isConfirmationModalOpen = false;
    },
  },

  watch: {
    isEditModeActive(booleanState) {
      //The user entered in edit mode, and there is a product previously selected
      if (
        booleanState === true &&
        !this.productIsEmpty &&
        this.isUniqEditMode
      ) {
        this.setEditableProduct({
          product: this.product,
          order: this.product.order,
          logoId: this.product.logoId,
          screenLogo: this.product.screenLogo,
        });
      }

      //Clear all
      if (booleanState === false) {
        this.isUniqEditMode = false;
        this.isEditPriceModeActive = false;
        this.isSearchModalOpen = false;
        this.isEditDecorationsActive = false;
      }
    },
    editableProductIsNew(booleanState) {
      if (booleanState === true) this.changeProductHasBeenEdited(false);
    },
  },
};
</script>

