<template>
  <div id="snapsheet-footer">
    <div class="copy">
      <span>Powered By</span>
      <span id="gameplan-logo">&nbsp; GAMEPLAN</span>
      <!-- TODO svg -->
    </div>
    <div class="dealer-actions">
      <span v-if="!dealerMode" class="action" @click="openDealerLogin()"
        >Dealer Login</span
      >
      <span v-else class="action" @click="dealerLogout()">Dealer Logout</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "Footer",
  computed: {
    ...mapGetters(["dealerMode"])
  },
  methods: {
    ...mapActions(["dealerLogout"]),
    openDealerLogin() {
      this.$emit("openDealerLogin");
    },
  },
  watch: {
    dealerApiToken() {
      this.$emit("closeDealerLogin");
    }
  }
};
</script>

<style lang="scss" scoped>
#app {
  div#snapsheet-footer {
    background-color: #f9f9f9;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    .content {
      width: 300px;
      display: flex;
      justify-content: center;
      span {
        padding: 0 2px;
      }
      #gameplan-logo {
        width: 150px;
      }
    }
    .dealer-actions {
      border-left: 1px solid #999;
      margin-left: 5px;
      .action {
        padding-left: 5px;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
</style>