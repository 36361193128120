<template>
  <article class="snap-card" v-if="!cardLoading">
    <div v-if="isEditModeActive && isUniqEditMode" class="close-container">
      <button class="close" @click="() => changeEditMode(false)">
        <span>X</span>
      </button>
    </div>

    <div class="vector-container" v-if="!productIsEmpty">
      <Vector
        :side="'front'"
        :svgs="svgs"
        :backgrounds="backgrounds"
        :layout="productLayout"
        :is_stock="!product.is_sublimable"
        :isVariantStock="!product.is_sublimable"
        :stamp_logo="stamp_logo"
        :logoId="product.logoId"
      />
      <button
        class="btn-liketext"
        v-if="ShowSwapButton"
        @click="$emit('handleSearchModal')"
      >
        <SwapIconSVG />
        <span>Swap Product</span>
      </button>

      <!-- Decorations button -->
      <button
        v-show="!dealerMode && !isProjectLocked"
        class="edit-decorations-btn btn"
        :class="{ disabled: !editDecorationsEnabled }"
        @click="$emit('handleEditDecorations')"
      >
        Edit Decorations
      </button>
    </div>

    <!-- Prices Grid and Color options -->
    <div class="description-pricing-colors-container" v-if="!productIsEmpty">
      <div class="header">
        <p class="name">{{ product.name }}</p>

        <!-- <div v-if="wasAddedToLocker">Added :D</div> -->

        <!-- <button v-if="isLoadingSaveToLockerProcess" class="add-to-locker-button">
          <Loader />
        </button> -->

        <AddToLockerButton
          v-show="!dealerMode && !isProjectLocked"
          :isLockerLoading="isLockerLoading"
          :isProjectLocked="isProjectLocked"
          :dealerMode="dealerMode"
          @addToLocker="$emit('addToLocker')"
        />
      </div>
      <!-- Description -->
      <div
        v-if="!showAllColorOptions"
        class="description-container"
        :class="{ 'full-description-container': showFullDescription }"
      >
        <p class="description">
          {{ productDescription }}
        </p>
        <button
          type="button"
          class="btn"
          v-if="productDescriptionIsTooLong && !showFullDescription"
          @click="toggleFullDescription"
        >
          Full description
        </button>
        <span
          class="close-full-description"
          v-if="productDescriptionIsTooLong && showFullDescription"
          @click="toggleFullDescription"
        >
          x
        </span>
      </div>

      <!-- Price Grid -->
      <div class="pricing-container" v-if="!showAllColorOptions">
        <div class="pricing__title">
          <button
            class="btn-liketext"
            v-if="isUniqEditMode && dealerMode"
            @click="$emit('handleEditPriceMode')"
          >
            Edit Prices
          </button>
        </div>

        <ul class="pricing__table">
          <li
            class="pricing__table--item"
            v-for="(pr, index) in gridPrice"
            :key="index + '-pricing-row'"
          >
            <p class="code">{{ pr.alias }}</p>
            <p class="gender">{{ pr.gender }}</p>
            <p class="size">{{ pr.sizes }}</p>
            <p v-if="!isEditPriceModeActive" class="price">${{ pr.price }}</p>
            <input
              v-if="isEditPriceModeActive && isUniqEditMode"
              class="price"
              :value="pr.price"
              type="number"
              step="0.05"
              @input="
                applyEditableProductPrices({
                  value: $event.target.value,
                  styleId: pr.style_id,
                })
              "
            />
          </li>
        </ul>
      </div>

      <!-- Color Options (Variants) -->
      <div class="color-options-container" v-if="showAllColorOptions">
        <p>All color options</p>
        <span class="close-full-colors" @click="toggleViewAllColorOptions">
          x
        </span>
        <div class="color-options">
          <ColorOption
            v-for="(design, index) in designs"
            :key="'opt' + arrayIndex + arrayIndex + index + pageId"
            :design="design"
            :product="originalProduct"
            :index="index"
            :productChanged="productChanged"
            v-on="{ onColorSelected: toggleViewAllColorOptions }"
          />
        </div>
      </div>

      <button
        type="button"
        class="btn toggle-color-options"
        v-if="!showAllColorOptions && !isProjectLocked"
        @click="toggleViewAllColorOptions()"
      >
        View all color options
      </button>
    </div>

    <div class="sub-line"></div>
  </article>

  <!-- Loader -->
  <article class="snap-card snap-card-loading" v-else>
    <Loader />
  </article>
</template>

<script>
import Vector from "@/components/Vector.vue";
import Loader from "@/components/Loader.vue";
import SwapIconSVG from "@/components/SwapIconSVG.vue";
import ColorOption from "@/components/Snapsheet/ColorOption.vue";
import AddToLockerButton from "@/components/Snapsheet/AddToLockerButton.vue";

import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "SnapsheetPageItemInfo",
  components: { Vector, ColorOption, Loader, SwapIconSVG, AddToLockerButton },
  props: [
    "originalProduct",
    "editableProduct",
    "pageId",
    "arrayIndex",
    "ShowSwapButton",
    "showSaveChangesButton",
    "isUniqEditMode",
    "isEditPriceModeActive",
    "cardLoading",
    "originalLogoImage",
    "screenLogo",
    "layouts",
    "productChanged",
    "editDecorationsEnabled",
    "",
  ],

  computed: {
    ...mapState("snapsheet", ["logo", "isEditModeActive"]),
    ...mapGetters(["dealerMode"]),
    ...mapGetters("snapsheet", ["editableProductGridPrice"]),
    ...mapGetters("actionsPermissions", ["isProjectLocked"]),
    ...mapState(["lockerModule"]),

    isLockerLoading() {
      return this.lockerModule.loadingLocker;
    },

    productDescriptionIsTooLong() {
      return this.product.description.length > 149;
    },

    product() {
      return this.isUniqEditMode ? this.editableProduct : this.originalProduct;
    },

    productDescription() {
      return !this.productDescriptionIsTooLong || this.showFullDescription
        ? this.product.description
        : this.product.description.substring(0, 147) + "...";
    },
    productLayout() {
      return this.product.activeLayoutId
        ? this.product.layouts.filter((layout) => {
            return layout.id === this.product.activeLayoutId;
          })[0]
        : this.product.layouts[0];
    },

    productIsEmpty() {
      return this.product == null || !this.product.id;
    },

    stamp_logo() {
      return this.product.screenLogo?.logo;
    },

    designs() {
      return this.product.variants; //this will change when we use sublimables
    },

    gridPrice() {
      return this.editableProductGridPrice(this.product);
    },

    svgs() {
      return this.product.design
        ? this.product.design
        : { group1: [], group2: [], group3: [] };
    },

    backgrounds() {
      return this.svgs.backgrounds;
    },
  },

  methods: {
    ...mapActions("snapsheet", [
      "applyEditableProductPrices",
      "changeEditMode",
      "addToLocker",
    ]),

    toggleFullDescription() {
      this.showFullDescription = !this.showFullDescription;
    },
    toggleViewAllColorOptions() {
      this.showAllColorOptions = !this.showAllColorOptions;
    },
  },

  data() {
    return {
      // showTest:false,
      showFullDescription: false,
      showAllColorOptions: false,
      wasAddedToLocker: false,
    };
  },
};
</script>

<style lang="scss" >
#app {
  .grid-card {
    display: flex;
    justify-content: space-between;
    position: relative;
    background-color: white;
    width: 100%;
    /* height: 28vh; */

    &.empty {
      border: 1px solid black;
    }

    &.edit-mode-active {
      flex-direction: column;
      height: fit-content;
      min-height: 340px;
      z-index: 10;

      .snap-card {
        margin-bottom: 0.5rem;

        .sub-line {
          display: none;
        }
      }
    }

    &.coach-mode {
      button.edit-decorations-btn {
        display: none;
        width: 100%;
        position: absolute;
        top: 50%;

        &.disabled {
          cursor: not-allowed !important;
        }
      }
    }

    &:hover:not(.edit-mode-active) {
      .edit-options-container {
        visibility: initial;
        pointer-events: initial;
        cursor: pointer;
        button {
          cursor: pointer;
        }
      }

      &.coach-mode {
        button.edit-decorations-btn {
          display: block;
        }
      }
    }

    button {
      cursor: pointer;

      &.btn {
        display: block;
        margin: auto;
        padding: 0.4rem;
        width: 35%;
        background-color: white;
        border-radius: 7px;
        border: none;
        box-shadow: 0 0 10px -2px #0000004d;
        color: black;
        font-family: "SlateStd";
        font-weight: bold;
        text-transform: uppercase;
        transition: box-shadow 0.2s ease;
        transition: background-color 0.15s ease;
        transition: color 0.15s ease;
        transition: transform 0.15s ease;

        &:hover {
          box-shadow: 0 0 8px 1px #00000099;
          background-color: black;
          color: white;

          transform: scale(1.05);
        }

        &:disabled{
          opacity: 0.75;
          cursor: not-allowed;
        }
      }

      &.btn-liketext {
        background: none;
        text-decoration: underline;
        border: none;
        text-transform: capitalize;
        color: rgb(66, 66, 66);
        padding: 0.5rem;
        border-radius: 10px;
        transition: all 0.2s;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
          background: black;
          color: whitesmoke;
          text-decoration: none;
          svg {
            fill: whitesmoke;
          }
        }

        svg {
          margin-right: 0.3rem;
          width: 16px;
          height: 16px;
          fill: black;
        }
        span {
          margin-left: 0.3rem;
        }
      }

      &.add-button {
        display: block;
        width: 20%;
        margin: auto !important;
        @media screen and (max-width: 768px) {
          width: 25%;
        }
      }

      &.save-button {
        background: black;
        color: whitesmoke;
        display: flex;
        width: 100%;
        padding: 1rem 0;
        justify-content: center;
        border: none;
        font-family: "ForzaBold";
        text-transform: uppercase;
        transition: all 0.2s;

        &:hover {
          color: wheat;
        }
      }
    }

    .snap-card {
      display: flex;
      justify-content: space-between;
      position: relative;
      background-color: white;
      width: 100%;
      padding: 0.4rem 1rem;
      padding-bottom: 0;
      flex-wrap: wrap;
      /* min-height: 280px; */

      .close-container {
        width: 100%; //
        display: flex;
        justify-content: flex-end;
        button {
          height: 30px;
          width: 30px;
          background: rgb(12, 12, 12);
          border: none;
          color: white;
          border-radius: 5px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.15s ease;
        }

        &:hover {
          button {
            box-shadow: 0 0 8px 1px #00000099;
            background-color: black;
            color: white;

            transform: scale(1.05);
          }
        }
      }

      .vector-container {
        width: 39%;
        max-height: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
        position: relative;

        .product-image {
          width: 100%;
          max-height: 100%;

          svg {
            max-width: 100%;
            height: 100%;
            margin: auto;
            display: block;
          }
        }
      }

      .description-pricing-colors-container {
        width: 60%;
        display: flex;
        flex-direction: column;

        .header {
          display: flex;
          .name {
            width: 100%;
            /* text-align: center; */
            font-weight: bold;
            font-size: 1.1rem;
            text-transform: uppercase;
            margin: 0;
            display: flex;
            align-items: center;
            padding: 0;
            /* color: red; */
          }
        }

        .description-container {
          position: relative;
          padding: 1rem 0;
          padding-bottom: 20px;
          margin-bottom: 1rem;

          .description {
            font-size: 12px;
          }
          button {
            position: absolute;
            right: 0;
            bottom: 0;
            box-shadow: none;
            padding: 2px;
          }
          .close-full-description {
            position: absolute;
            top: 0;
            right: 5px;
            cursor: pointer;
            font-size: 16px;
            font-weight: bold;
          }
          &.full-description-container {
            box-shadow: 0 0 10px -2px #0000004d;
            padding: 10px;
          }
        }

        .pricing-container {
          margin-bottom: 1rem;
          .pricing__title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
          }

          ul.pricing__table {
            display: flex;
            flex-flow: column;
            list-style: none;

            li {
              display: flex;
              justify-content: space-between;
              padding: 0.2rem !important;
              background-color: rgb(251, 251, 251);

              &:nth-child(odd) {
                background-color: rgb(225, 225, 225);
              }

              p,
              input {
                width: 25%;
                text-transform: capitalize;
              }
            }
          }
        }

        .toggle-color-options {
          width: auto;
          padding: 0.5rem 1rem;
          margin-top: 0 !important;
        }

        .color-options-container {
          padding: 0 0.5rem;
          box-shadow: 0 0 10px -2px #0000004d;
          position: relative;
          .close-full-colors {
            position: absolute;
            top: 0;
            right: 5px;
            cursor: pointer;
            font-size: 16px;
            font-weight: bold;
          }
          p {
            text-transform: uppercase;
            margin: 0.5rem;
            text-align: center;
            font-size: 14px;
          }
          .color-options {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            overflow-y: auto;
            padding: 5px;
            p {
              font-size: 0.7rem;
              margin: 0;
            }
          }
        }

        button.add-to-locker-button {
          &.disabled {
            cursor: not-allowed !important;
          }
        }
      }

      .sub-line {
        width: 90%;
        height: 1px;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        background: rgb(206, 197, 197);
      }

      &.snap-card-loading {
        justify-content: center;
        align-items: center;
        margin: auto;
      }

      @media screen and (max-width: 576px) {
        display: flex;
        /* flex-direction: column; */
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 0.3rem;

        .vector-container {
          max-width: 100%;
          margin: auto;
        }

        .description-pricing-colors-container {
          width: 100%;
          margin-bottom: 1rem;
        }
      }
    }

    .edit-options-container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background-color: #6e6d6d99;
      visibility: hidden;
      /* pointer-events: none; */

      button {
        &.disabled {
          cursor: not-allowed !important;
        }

        &.delete {
          position: absolute;
          left: 1rem;
          top: 1rem;
          background-color: black;
          padding: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;

          &:hover {
            background: rgb(218, 28, 28);
          }
        }

        &.add-to-locker-button {
          position: absolute;
          right: 1rem;
          top: 1rem;
          background-color: black;
          color: white;
          padding: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
        }
      }
    }

    @media screen and (max-width: 768px) {
      &.edit-mode-active {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: scroll;
        height: auto;
      }
    }
  }
}
</style>