<template>
  <div id="manage-logos-modal" class="modal">
    <div class="manage-logos-container modal-dialog">
      <div class="modal-content">
        <!-- Header -->
        <div class="header modal-header">
          <div class="header-text">
            <h3>{{ customerName }} Graphics</h3>
            <p>
              Please select from any existing graphics or upload a new one. Want
              to leave the garment blank? Unclick the active logo.
            </p>
          </div>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- Logos Selection -->
        <div class="logos-container">
          <!-- Show all Logos -->
          <article
            class="logo-wrapper"
            v-for="logo in uploadedLogos"
            :key="'logo-option-' + logo.id"
          >
            <!-- Select Logos -->
            <label :for="'logo-' + logo.id" class="logo-label-wrapper">
              <input
                type="radio"
                :name="'logo-' + logo.id"
                :id="'logo-' + logo.id"
                :value="logo.id"
                v-model="selectedLogoId"
                @click.stop="handleUnselectLogo(logo.id)"
              />
              <div
                class="logo-image-container d-flex justify-content-center align-center"
              >
                <img
                  :src="logo.fileUploaded ? logo.fileUploaded : logo"
                  alt="logo"
                />
                <article class="check-container">
                  <div class="check"></div>
                </article>
              </div>
            </label>
          </article>
        </div>

        <!-- Upload Button -->
        <UploadLogoButton
          :graphicButtonHint="'Upload new graphic'"
          :refName="'fileToSave'"
          @uploadingLogo="emitUploadingLogo"
          @logoHasBeenUploaded="emitLogoHasBeenUploaded"
        />

        <!-- Cancel / Save -->
        <div class="d-flex flex-row justify-content-between buttons-wrapper">
          <button
            v-if="!loading"
            type="button"
            class="btn btn-outline-brand btn-tall"
            @click="closeModal()"
          >
            CANCEL
          </button>
          <button
            v-if="!loading"
            type="button"
            class="btn btn-brand btn-tall"
            @click="save"
          >
            SAVE CHANGES
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadLogoButton from "@/components/Logos/UploadLogoButton.vue";

import { mapGetters, mapState } from "vuex";

export default {
  name: "ManageLogoModals",
  props: [
    "uploadedLogos",
    "preselectedLogoId",
    "MAX_LOGOS_ALLOWED",
    "isModalOpen",
  ],
  components: { UploadLogoButton },
  data() {
    return {
      selectedLogoId: "",
      loading: false,
    };
  },

  computed: {
    ...mapState(["dealer", "settings"]),

    ...mapGetters(["getColorById"]),
    ...mapGetters("customer", ["customerName"]),
  },

  methods: {
    handleUnselectLogo(logoId) {
      if (this.selectedLogoId == logoId) this.selectedLogoId = "";
    },
    getTypeOfLogoLabel(logo) {
      return logo.typeOfLogo?.length
        ? logo.typeOfLogo?.length > 1
          ? "Uniforms / Spiritwear"
          : logo.typeOfLogo[0]
        : "All";
    },

    checkDisableMode(logoId) {
      return this.selectedLogoId !== logoId;
    },

    recalculateSelectedLogoId() {
      this.selectedLogoId = this.preselectedLogoId || "";
    },

    closeModal() {
      this.$emit("closeModal");
    },

    save() {
      // Remove logo
      if (!this.selectedLogoId) {
        this.$emit("removeLogo");
      } else {
        this.$emit("changeSelectedLogo", this.selectedLogoId);
      }
    },

    emitUploadingLogo(file) {
      this.$emit("uploadingLogo", file);
    },

    emitLogoHasBeenUploaded(newLogo) {
      this.$emit("logoHasBeenUploaded", newLogo);
    },
  },

  mounted() {
    this.recalculateSelectedLogoId();
  },

  watch: {
    preselectedLogoId() {
      this.recalculateSelectedLogoId();
    },
  },
};
</script>

<style lang="scss" scoped>
div#manage-logos-modal {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  .manage-logos-container {
    max-width: 1086px;
    background: white;

    @media (max-width: 767px) {
      height: 90%;
    }

    .modal-content {
      border: none;
      max-width: 768px;
      min-width: 576px;

      @media (max-width: 768px) {
        height: 100%;
        overflow: auto;
        width: 425px;
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.25rem;
        border-bottom: 1px solid #ebedf2;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);

        .header-text {
          display: flex;
          flex-flow: column;
          width: 75%;

          h3 {
            margin: 0;
            color: black;
            font-size: 1.3rem;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 1rem;
          }

          
          p {
            font-size: 1rem;
            color: grey;
          }
        }
        button {
          color: #000;
          float: right;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1;
          margin-bottom: auto;
          opacity: 0.5;
          padding: 0.25rem;
          text-shadow: 0 1px 0 #fff;
        }
      }

      .logos-container {
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
        margin: auto;
        width: 95%;
        flex-wrap: wrap;
        padding-top: 1rem;
        max-height: 350px;
        overflow-y: scroll;
      }

      .logo-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
        /* margin: 1rem;
        padding: 1rem;
        width: 200px; */

        h4 {
          height: 50px;
        }

        .logo-label-wrapper {
          cursor: pointer;
          border: 1px solid #cecece;
          border-radius: 5px;
          margin-bottom: 1rem;
          position: relative;

          &.disabled {
            filter: opacity(0.5);
            cursor: not-allowed;
          }

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            transition: all 0.2s;
          }

          input:checked {
            & ~ .logo-image-container {
              border: 5px solid rgb(63, 192, 31) !important;
            }

            & ~ .logo-image-container {
              .check-container {
                --borderWidth: 3px;
                --height: 12px;
                --width: 6px;
                --borderColor: white;

                background: rgb(63, 192, 31);
                border: 1px solid white;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: -10px;
                right: -10px;

                .check {
                  display: inline-block;
                  transform: rotate(45deg);
                  height: var(--height);
                  width: var(--width);
                  border-bottom: var(--borderWidth) solid var(--borderColor);
                  border-right: var(--borderWidth) solid var(--borderColor);
                }
              }
            }
          }

          .logo-image-container {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
            width: 150px;
            height: 150px;
            border: 5px solid transparent !important;
            border-radius: 5px;
            padding: 10px;
            transition: all 0.1s;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }

        .edit-button {
          display: block;
          margin: auto;
          background: none;
          border: none;
          border-bottom: 1px solid black;
          transition: all 0.1s;

          &:focus {
            border: 1px solid black;
          }

          &:hover {
            background: black;
            color: white;
          }
        }
      }

      .upload-logo {
        padding: 1rem;

        label {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .buttons-wrapper {
        padding: 1rem;
        border-top: 1px solid #ebedf2;
      }
    }
  }

  @media (max-width: 768px) {
    background: white;
    width: 100%;
    height: 100%;

    .manage-logos-container {
      width: 100%;
      height: 100%;

      .modal-content {
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        min-width: 100%;

        .logos-container {
          max-height: initial !important;
        }
      }
    }
  }
}
</style>