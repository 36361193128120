import axios from "axios"
import CONSTANTS from '@/constants.js';
const endPointURL = "https://qa-ss.teamworkathletic.com/",
  apiUrl = "https://rhino2.dev2.axlot.com/" //todo env

const screenLogo = {
  namespaced: true,
  state: () => ({
    graphicsLoading: false,
    apiLoading: false,
    modalOpened: false,
    file: null,
    fileSize: null,
    originalFile: null,
    converteditedFile: null,
    apiLogos: null,
    editMode: false,
    position: CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.position,
    zoom: CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.zoom,
    aspectRatio: true,
    rendering: false,
    editableScreenLogo: ''
  }),

  getters: {
  },

  actions: {
    //initialize state

    setScreenLogoProperties({ commit }, payload) {
      // console.log(payload);
      commit('setZoom', payload.zoom);
      commit('setPosition', payload.position);
      commit('setAspectRatio', payload.aspectRatio);
      commit('setEditableScreenLogo', payload.originalGraphic)
    },

    /*
    // Uploads graphic to Peter's endpoint
    */
    uploadGraphic({ commit, dispatch }, payload) {
      dispatch("setApiLoading", true)
      //commit("setFile", payload.file)

      let formData = new FormData
      formData.append("File", payload.file)
      axios.post(
        endPointURL + "Services/SCGGraphicService.svc/UploadGraphicFile" +
        "?emailId=" + payload.emailId +
        "&AccountNumber=&fileName=" + payload.fileName +
        "&imageName=" + payload.imageName,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )
        .then((response) => {
          if (response.data.Error == null) {
            commit("appendGraphic", response.data.ResponseData)
            //If successfully added file to Peters API
            //we need to have a base64 of the image, the previewimageurl is not enought
            //because we cannot use tainted img in html2canvas
            dispatch("uploadGraphicRhino", payload)
          }
          else {
            this._vm.$toastr.e(response.data.Error.ErrorDescription);
          }
        })
        .finally(() => {
          dispatch("setApiLoading", false)
        })
    },
    DIGIrequestImageAsBse64({ dispatch, commit }, payload) {
      //I send a PNG url to Rhino and I get a base64
      dispatch("setApiLoading", true)
      axios.get(
        apiUrl + "/api/bsn/to-base64",
        {
          params: payload
        }
      ).then((response) => {
        //I use the base64 for the modal which will use html2canvas
        commit("setEditableScreenLogo", {
          content: "data:image/png;base64," + response.data
        })
      })
        .finally(() => {
          dispatch("setApiLoading", false)
        })
    },
    uploadGraphicRhino({ dispatch, commit }, payload) {
      dispatch("setApiLoading", true)
      commit("setFile", payload.file)

      let formData = new FormData
      formData.append("file", payload.file)
      axios.post(
        apiUrl + "api/v2/upload/logo",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then((response) => {
          commit("setEditableScreenLogo", response.data)
        })
        .finally(() => {
          dispatch("setApiLoading", false)
        })
    },
    setLoading({ commit }, loadingState) {
      commit("setLoading", loadingState)
    },
    setApiLoading({ commit }, loadingState) {
      commit("setApiLoading", loadingState)
    },
    triggerModal({ commit }, modalState) {
      commit("triggerModal", modalState)
    },
    setApiGraphic({ commit }, payload) {
      commit("setApiGraphic", payload)
    },
    setPosition({ commit }, payload) {
      commit("setPosition", payload)
    },
    setZoom({ commit }, payload) {
      commit("setZoom", payload)
    },
    setAspectRatio({ commit }, payload) {
      commit("setAspectRatio", payload)
    },
    requestGraphics({ commit }, payload) {
      //requests logos to flash endpoint using email or dealerid
      commit("setApiLoading", true)
      axios.post(
        //endPointURL + 'Services/SCGGraphicService.svc/GetLockerFileList', {
        apiUrl + "api/bsn/locker-file-list", {
        dealerId: payload.dealerId ? payload.dealerId : "",
        email: payload.emailId ? payload.emailId : ""
      }
      )
        .then((response) => {
          if (!response.data.ResponseData) {
            // console.log("api warning")
            // console.log(response.data)
          }
          commit("setApiGraphics", {
            graphics: response.data.ResponseData,
            email: payload.emailId
          })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit("setApiLoading", false)
        })
    },
    saveDealerCustomizations({ commit }) {
      axios.post(
        endPointURL + 'Services/SCGService.asmx/SaveDealerCustomizations', {
        "productOrderId": "2", "rosterItems": { "RosterItems": [{ "TeamName": "team name test", "PlayerName": "player name test", "PlayerNumber": "00", "GenderName": "Male", "Quantity": "1", "Size": "L", "UnitPrice": 13, "TaaskuId": "8820-5B-L" }], "SessionVariable": "37c1a4a9-ba51-4211-8b10-375d5182dea9" }
      }
      )
        .then(() => {
          // console.log(response)
          commit("setLoading", false)

        })

    },
    closeSession({ commit }) {
      axios.post(
        endPointURL + 'Services/SCGATGService.asmx/CloseSession', {
        SessionVariable: "37c1a4a9-ba51-4211-8b10-375d5182dea9"
      }
      )
        .then(() => {
          // console.log(response)
          commit("setLoading", false)

        })
    },
    applyCustomizations({ commit }) {
      //this goes into another module
      axios.post(
        endPointURL + 'Services/SCGService.asmx/ApplyCustomization', {
        playerName: "player name test",
        playerNameColorId: "90004",
        playerNameOutlineColorId: "90002",
        playerNoColorId: "90002",
        playerNoOutlineColorId: "90004",
        playerNumber: "00",
        primaryColorId: "90001",
        productOrderId: "2",
        secondaryColorId: "90002",
        teamName: "team name test",
        teamNameColorId: "90003",
        teamNameOutlineColorId: "90002",
        tertiaryColorId: "90003",
        uploadedGraphicFullSizeUrl: "https://s3-us-west-1.amazonaws.com/cug-upload-test/test@test.com/descarga.jpg",
        uploadedGraphicPreviewUrl: "https://s3-us-west-1.amazonaws.com/cug-upload-test/test@test.com/preview/descarga.png",
      }
      )
        .then(() => {
          // console.log(response)
          commit("setLoading", false)

        })
    },
    setApiLogo({ commit }, payload) {
      commit("setApiLogo", payload)
    },
    setEditableScreenLogo({ commit }, payload) {
      commit("setEditableScreenLogo", payload)
    }
  },

  mutations: {
    appendGraphic: (state, payload) => {
      state.apiLogos.graphics = [...state.apiLogos.graphics, payload]
    },
    setUploadedGraphic: (state, payload) => {
      state.originalFile = payload.url
      state.converteditedFile = payload.content
    },
    setFile: (state, file) => {
      let fSExt = new Array("Bytes", "KB", "MB", "GB"),
        i = 0,
        _size = file.size;
      while (_size > 900) {
        _size /= 1024;
        i++;
      }
      state.file = file
      state.fileSize = Math.round(_size * 100) / 100 + " " + fSExt[i]
    },
    setApiLoading: (state, loadingState) => {
      state.apiLoading = loadingState
    },
    setLoading: (state, loadingState) => {
      state.graphicsLoading = loadingState
    },
    setField: (state, payload) => {
      state[payload.field] = payload.value
    },
    triggerModal: (state, modalState) => {
      state.modalOpened = modalState
      state.editMode = false
    },

    setApiGraphics: (state, payload) => {
      state.apiLogos = payload
    },

    setApiGraphic: (state, payload) => {
      state.converteditedFile = payload.PreviewImageUrl
      state.modalOpened = false
    },
    setEditableScreenLogo: (state, payload) => {
      state.editableScreenLogo = payload
    },
    setPosition: (state, payload) => {
      state.position = payload || CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.position
    },
    setZoom: (state, payload) => {
      state.zoom = payload || CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.zoom
    },
    setAspectRatio: (state, payload) => {
      state.aspectRatio = payload
    },
    //test
    setApiLogo: (state, payload) => {
      state.converteditedFile = payload.PreviewImageUrl
      state.originalFile = payload.FullSizeImageUrl
      state.modalOpened = false
    }
  }
}

export default screenLogo