<template>
  <article class="snapsheet-page">
    <div
      class="project-title-container"
      v-if="!editTitleActive || !editTitleEnabled"
    >
      <h2 class="project-title">{{ projectTitle }}</h2>
      <span
        class="project-title-edit-action"
        v-if="editTitleEnabled && dealerMode"
        @click="toggleEditTitle"
        >Edit Project Title</span
      >
    </div>
    <div
      class="project-title-container"
      v-else-if="editTitleEnabled && dealerMode"
    >
      <input
        type="text"
        class="form-control"
        maxlength="30"
        v-model="tempTitle"
      />
      <div class="edit-action-buttons">
        <button
          type="button"
          class="btn"
          title="Cancel"
          @click="toggleEditTitle"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn save"
          title="Save"
          @click="saveEditTitle"
        >
          Save
        </button>
      </div>
    </div>
    <transition-group name="list" tag="article" class="list">
      <SnapsheetPageItem
        v-for="(product, index) in productsInPage"
        :key="index + '-item-' + pageId"
        :product="product"
        :pageId="pageId"
        :arrayIndex="index"
        @openPreviewNewLockerProducts="emitOpenPreviewNewLockerProducts"
      />
    </transition-group>
    <p class="page-footer">{{ pageId }}</p>

  </article>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import SnapsheetPageItem from "@/components/Snapsheet/SnapsheetPageItem.vue";

export default {
  name: "SnapsheetPage",
  components: { SnapsheetPageItem },

  props: {
    pageId: {
      type: Number,
    },
    productsInPage: {
      type: Array,
    },
  },

  data() {
    return {
      tempTitle: "",
      screenWidth: window.innerWidth,

    };
  },

  computed: {
    ...mapState("snapsheet", [
      "products",
      "pages",
      "projectTitle",
      "editTitleActive",
    ]),
    ...mapGetters(["dealerMode"]),


    isMobile() {
      return this.screenWidth <= 1024;
    },

    editTitleEnabled() {
      if (this.isMobile) return true;
      return !!(this.pageId % 2);
    },
  },

  methods: {
    ...mapActions("snapsheet", [
      "toggleEditTitleActive",
      "updateSnapsheetTitle",
    ]),
    saveEditTitle() {
      this.updateSnapsheetTitle({ title: this.tempTitle });
      this.toggleEditTitleActive();
    },
    toggleEditTitle() {
      this.tempTitle = this.projectTitle;
      this.toggleEditTitleActive();
    },

    onResize() {
      this.screenWidth = window.innerWidth;
    },

    emitOpenPreviewNewLockerProducts() {
      this.$emit("openPreviewNewLockerProducts");
    },


  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss" scoped>
#pages-container {
  .snapsheet-page {
    height: 100%;
    width: 100%;
    box-shadow: 0 0 6px 2px #0000001c;

    .project-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 2rem;
      border-bottom: 3px solid #666;
      .form-control {
        width: 50%;
        padding: 6px;
        border-radius: 3px;
        border: 1px solid #666;
      }
      .edit-action-buttons {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        button {
          width: 100px;
          margin: 0 5px;
          display: block;
          padding: 0.4rem;
          background-color: white;
          border-radius: 7px;
          border: none;
          box-shadow: 0 0 10px -2px #0000004d;
          color: black;
          font-family: "SlateStd";
          font-weight: bold;
          text-transform: uppercase;
          transition: box-shadow 0.2s ease;
          transition: background-color 0.15s ease;
          transition: color 0.15s ease;
          &:hover {
            cursor: pointer;
            color: white;
            background-color: black;
          }
          &.save {
            color: white;
            background-color: black;
          }
        }
      }
      .project-title-edit-action {
        font-size: 14px;
        color: #666;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .list {
      display: grid;
      grid-template-rows: repeat(3, 300px);
      padding: 7px;
    }

    .page-footer {
      grid-area: 5/1/-1/-1;
      font-family: "ForzaBold";
      font-weight: bold;
      padding: 5px 15px;
    }

    &:last-of-type {
      .page-footer {
        text-align: right;
      }
    }

    &:first-of-type {
      .page-footer {
        text-align: left;
      }
    }

    @media screen and (max-width: 1024px) {
      .list {
        grid-template-rows: repeat(3, minmax(340px, auto));
      }
    }

    @media screen and (max-width: 576px) {
      .list {
        grid-template-rows: repeat(3, minmax(285px, auto));
      }
    }
  }

  .list-item {
    /* display: inline-block;
    margin-right: 10px; */
    transform: translateX(0px);
  }
  .list-enter-active,
  .list-leave-active {
    /* display: none; */
    transition: all 0.2s;
  }
  .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    /* transform: translateX(30px); */
  }

  .list-enter,
  .list-enter-active {
    display: none;
  }
}
</style>