<template>
  <!-- Search products -->
  <section id="search-products-container">
    <div v-if="isEditModeActive" class="close-card-container">
      <button class="close" @click="() => changeEditMode(false)">
        <span>X</span>
      </button>
    </div>
    <SearchBar :productQuery="productQuery" @input="handleInputChange" />
    <SearchResults
      :productQuery="productQuery"
      :products="products"
      :loading="loading"
      :order="order"
      :originalLogoImage="originalLogoImage"
      :screenLogo="screenLogo"
      v-on="{ onSelectedProduct: emitCloseModal }"
    />
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import axios from "axios";
import _ from "lodash";

import SearchBar from "@/components/Snapsheet/SearchBar.vue";
import SearchResults from "@/components/Snapsheet/SearchResults.vue";
export default {
  name: "SearchProductsModal",
  components: {  SearchBar, SearchResults },

  props: {
    isModalOpen: {
      type: Boolean,
    },
    order: {
      type: [Number],
    },
    originalLogoImage: {},
    screenLogo: {},
  },

  data() {
    return {
      productQuery: "",
      products: [],
      loading: false,
      error: false,
    };
  },

  computed: {
    ...mapState(["colors", "endpoint"]),
    ...mapState("snapsheet", ["color_1", "isEditModeActive"]),
    ...mapGetters("snapsheet", ["remainProductsToComplete"]),
  },

  methods: {
    ...mapActions("snapsheet", ["changeEditMode"]),

    handleInputChange(value) {
      this.productQuery = value;
    },

    fetchProductsByQuery: _.debounce(async function () {
      if (this.productQuery.length >= 3) {
        this.loading = true;

        try {
          const { data } = await axios.get(
            this.endpoint + "api/snapsheets/action/search",
            {
              params: {
                query: this.productQuery,
                color_1: this.color_1,
              },
            }
          );

          this.products = data;
        } catch (error) {
          
          this.error = true;
        } finally {
          this.loading = false;
        }
      } else if (this.productQuery.length == 0) {
        this.products = [];
      }
    }, 500),

    emitCloseModal() {
      this.$emit("onCloseModal");
    },
  },
  watch: {
    productQuery() {
      this.fetchProductsByQuery();
    },
  },
};
</script>

<style lang="scss">
#search-products-container {
  width: 100%;
  overflow-y: visible;
  height: 100%;
  padding: 5% 10%;

  .close-card-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.4rem;
    button {
      height: 30px;
      width: 30px;
      background: rgb(12, 12, 12);
      border: none;
      color: white;
      border-radius: 5px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 576px) {
    padding: 0.5rem;
  }
}
</style>