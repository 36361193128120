<template>
  <div id="snapsheet-footer-menu">
    <div class="snapsheet-footer-menu-column colors">
      <div
        class="color-picker-container"
        :class="{ disabled: isProjectLocked }"
      >
        <h5>Featured color:</h5>
        <ColorPicker
          :selected="selectedColor"
          :field="'color_1'"
          :isActive="opened_pickers.color_1"
          v-on="{ emitSelectColor: openModal }"
          @on-open-picker="handleOpenPicker"
        />
      </div>
      <div class="featured-gender-container">
        <h5>Featured gender:</h5>
        <select
          v-model="activeGender"
          clas="form-control"
          :disabled="isProjectLocked"
        >
          <option disabled>Select an option</option>
          <option
            v-for="(genderOpt, index) in genders"
            :key="index + '-g-opt'"
            :value="genderOpt"
            :selected="isSelectedGender(genderOpt)"
          >
            {{ genderOpt }}
          </option>
        </select>
      </div>
    </div>
    <div
      v-if="paginatedPages >= 2"
      class="snapsheet-footer-menu-column paginator"
    >
      <Pagination
        :total_pages="paginatedPages"
        :selected_page="actualPaginatedPageSelected"
        v-on="{ changePage }"
      />
    </div>

    <!-- Confirmation modal -->
    <ModalConfirmation
      :isModalOpen="isModalOpen"
      :title="'You are about to change your Snap Sheets featured color. Doing so will override any individual color changes you might have done so far.'"
      @confirm="handleSelectColor"
      @cancel="closeModal"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import ColorPicker from "@/components/Snapsheet/ColorPicker.vue";
import ModalConfirmation from "@/components/Utils/ModalConfirmation.vue";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  name: "SnapsheetFooter",
  props: ["paginatedPages", "actualPaginatedPageSelected"],
  components: { Pagination, ColorPicker, ModalConfirmation },
  computed: {
    ...mapState("snapsheet", ["color_1", "products", "gender"]),
    ...mapState(["colors"]),
    ...mapGetters(["dealerMode"]),
    ...mapGetters("actionsPermissions", ["isProjectLocked"]),
    selectedColor() {
      return this.colors.find((color) => {
        return color.id === this.color_1;
      });
    },
  },
  methods: {
    ...mapActions("snapsheet", [
      "updateFeaturedColor",
      "updateSnapsheetGender",
    ]),
    changePage(pageNumber) {
      this.$emit("changePage", pageNumber);
    },
    handleOpenPicker(payload) {
      if (!this.isProjectLocked)
        this.opened_pickers[payload.field] = payload.event;
    },

    openModal(colorData) {
      this.opened_pickers.color_1 = false;
      this.temporalColorSelected = colorData;
      this.isModalOpen = true;
    },

    closeModal() {
      this.isModalOpen = false;
      this.opened_pickers.color_1 = false;
      this.temporalColorSelected = null;
    },

    handleSelectColor() {
      if (!this.isProjectLocked) {
        this.updateFeaturedColor({
          color: this.temporalColorSelected,
          wrapperIds: this.products.map((product) => {
            return product.wrapperId;
          }),
        });

        this.closeModal();
      }
    },

    isSelectedGender(genderOpt) {
      return this.gender == genderOpt;
    },
  },
  data() {
    return {
      created: false,
      opened_pickers: {
        color_1: false,
      },
      activeGender: "",
      genders: ["Men", "Women"],
      isModalOpen: false,
      temporalColorSelected: null, //With this approach, we set a temporal selected color. It will be set if the user confirms the action, otherwise we delete it
    };
  },
  mounted() {
    this.activeGender = this.gender;
  },
  watch: {
    activeGender() {
      this.activeGender !== this.gender &&
        this.updateSnapsheetGender(this.activeGender);
    },
  },
};
</script>

<style lang="scss" scoped>
#snapsheet-footer-menu {
  margin: 20px auto;
  width: 100%;
  max-width: 1366px;
  display: flex;
  align-items: center;
  grid-area: 2/1/3/-1;

  .snapsheet-footer-menu-column {
    width: 50%;
    display: flex;
    margin: auto;

    &.paginator {
      width: 30%;
    }

    &.colors {
      .color-picker-container {
        display: flex;
        align-items: flex-end;
        margin-right: 10px;

        &.disabled {
          cursor: not-allowed;

          ::v-deep .color-picker-swatch {
            cursor: not-allowed !important;
          }
        }

        h5 {
          margin-right: 5px;
        }
      }
      .featured-gender-container {
        display: flex;
        align-items: flex-end;
        select {
          padding: 1px 30px 1px 10px;
        }
        h5 {
          margin-right: 5px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;

    .snapsheet-footer-menu-column {
      &.paginator {
        width: 90%;
      }

      &.colors {
        margin-bottom: 2rem;
        position: relative;

        .color-picker-container {
          width: 100%;
          justify-content: space-around;

          .color-picker {
            position: initial;

            .color-picker-list {
              position: absolute;
              left: 0;
              right: 0;
              margin: auto;
              flex-wrap: wrap;
              align-items: center;
              justify-content: space-between;

              .color-picker-swatch {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .snapsheet-footer-menu-column.colors {
      flex-direction: column;
      align-items: center;
      .color-picker-container {
        margin: 0;
        margin-bottom: 1rem;
      }
    }
  }
}
</style>