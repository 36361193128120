<template>
  <div
    id="nav-header"
    v-if="!(dealerSettings && dealerSettings.hide_dealer_header === false)"
  >
    <div id="dealer-header-container">
      <div
        id="dealer-highlight-color"
        :style="{ 'background-color': dealerData.header_color }"
      >
        &nbsp;
      </div>
      <div id="dealer-header" class="page container">
        <!-- Dealer Logo -->
        <router-link class="dealer-logo" :to="homepageRoute">
          <div :style="{ 'justify-content': logoPosition }">
            <img
              :style="{ 'max-width': dealerData.logo_size + '%' }"
              :src="dealerData.logo"
            />
          </div>
        </router-link>

        <!-- Dealer message -->
        <div class="dealer-msg">{{ dealerData.header_message }}</div>

        <div class="locker-and-search-container">
          <!-- Go to Locker -->
          <router-link class="locker-link" :to="lockerRoute">
            <!-- LOCKER ICON -->
            <img
              v-if="isProjectLocked"
              class="locked-img"
              :src="require('@/assets/icons/lock.svg')"
              @click="isProjectUnlockeable && openModal()"
            />

            <span>My Locker </span>
            <span class="locker-info-count">{{ lockerProductsLength }}</span>
          </router-link>

          <!-- Search -->
          <!-- <div id="search-container">
            
            <label for="header-search" class="header-search">
              Search
              <input
                id="header-search"
                type="text"
                placeholder=""
                v-model="searchQuery"
              />
            </label>
           
            <ul
              id="header-search-results-container"
              :class="{
                active: searchQuery.length >= 3,
              }"
            >
              <li class="search-result-item">Result item</li>
              <li class="search-result-item">Result item</li>
              <li class="search-result-item">Result item</li>
              <li class="search-result-item">Result item</li>
              <li class="search-result-item">Result item</li>
              <li class="search-result-item">Result item</li>
            </ul>
          </div> -->
        </div>

        <!-- Rep info -->
        <div class="rep-info-container">
          <div class="rep-info" v-if="repData">
            <h3>{{ repData.name }}</h3>
            <span>{{ repData.job_title }}</span>
            <span>{{ repData.phone }}</span>
            <span>{{ repData.email }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Confirmation modal -->
    <ModalConfirmation
      :isModalOpen="isModalOpen"
      :title="'Are you sure you want to unlock this snapsheet?'"
      @confirm="handleGoToLocker"
      @cancel="closeModal"
    />
  </div>
</template>

<script>
import CONSTANTS from "@/constants.js";
import { mapActions } from "vuex";
import ModalConfirmation from "@/components/Utils/ModalConfirmation.vue";
export default {
  name: "DealerHeader",
  components: { ModalConfirmation },
  props: [
    "dealerData",
    "repData",
    "homepageRoute",
    "lockerRoute",
    "lockerProductsLength",
    "isProjectLocked",
    "isProjectUnlockeable",
    "dealerMode",
  ],

  data() {
    return {
      searchQuery: "",
      isModalOpen: false,
    };
  },

  computed: {
    lel() {
      return CONSTANTS.PROJECT_STATUS.OPEN_STATUS;
    },
    logoPosition() {
      if (this.dealerSettings) {
        switch (this.dealerSettings.logo_alignment) {
          case "0":
            return "flex-start";
          case "1":
            return "center";
          case "2":
            return "flex-end";
        }
      }
      return "flex-start";
    },

    dealerSettings() {
      return this.dealerData.settings;
    },
  },

  methods: {
    ...mapActions("snapsheet", ["updateSnapsheetStatus"]),
    async handleGoToLocker() {
      if (this.isProjectUnlockeable) {
        try {
          await this.updateSnapsheetStatus(
            CONSTANTS.PROJECT_STATUS.OPEN_STATUS
          );
          return true;
        } catch (error) {
          console.log(error);
          this.$toastr.e("Error trying to unlock this snapsheet");
        } finally {
          this.closeModal();
        }
      } else {
        return true;
      }
    },

    openModal() {
      this.isModalOpen = true;
    },

    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#nav-header {
  border-bottom: 0.5rem solid whitesmoke;
}

#dealer-header-container {
  #dealer-highlight-color {
    height: 10px;
  }
  #dealer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    max-width: 1366px;
    margin: auto;
    .dealer-logo {
      width: 20%;
      display: flex;
      justify-content: flex-start;
      img {
        cursor: pointer;
        max-width: 100%;
        max-height: 75px;
      }
    }
    .dealer-msg {
      width: 40%;
      padding: 1.5rem;
      text-align: center;
    }

    .locker-and-search-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .locker-link {
        display: block;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        span {
          color: black;
          padding: 0 0.5rem;
        }
        span.locker-info-count {
          padding: 0.3rem 2rem;
          background: #e5e5e5;

          border-radius: 10px;
          margin-left: 1rem;
        }
      }
    }

    .rep-info-container {
      width: 20%;
      .rep-info {
        display: flex;
        flex-direction: column;
        word-break: keep-all;
        align-items: flex-end;
      }
    }

    .header-search {
      display: flex;
      flex-direction: column;

      input {
        padding: 0.2rem;
      }
    }

    @media screen and (max-width: 576px) {
      flex-flow: column;
      & > * {
        margin-bottom: 1rem;
        width: 100%;
      }

      .rep-info-container {
        width: 100%;
      }

      .rep-info {
        & > * {
          margin-bottom: 1rem;
          width: 100%;
        }
      }
      .dealer-logo {
        img {
          margin: auto;
        }
      }
    }
  }

  @media screen and (max-width: 786px) {
    display: none;
  }
}

#search-container {
  position: relative;
  #header-search-results-container {
    position: absolute;
    top: 50px;
    left: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 200px;
    width: 100%;
    transform: scaleY(0);
    transform-origin: top;
    transition: all 0.2s;
    border-radius: 5px;
    border: none;
    overflow-y: auto;
    box-shadow: 0px 9px 5px -3px #00000087;
    z-index: 1000;
    &.active {
      transform: scale(1);
    }

    @media screen and (max-width: 768px) {
      max-height: 350px;
    }

    .search-result-item {
      display: flex;
      align-items: center;
      padding: 0.6rem 1rem;
      background-color: white;
      cursor: pointer;
      text-align: center;
      transition: all 0.15s;

      &:nth-child(odd) {
        background: #f2f2f2;
      }

      &:not(.inactive):hover {
        background-color: rgb(19, 19, 19);
        color: wheat;
      }

      p:first-child {
        font-weight: bold;
        margin-right: 1rem;
      }

      &.inactive {
        background: rgb(187, 187, 187);
        box-shadow: initial;
        cursor: not-allowed;

        p {
          color: white;
        }

        p:last-child {
          font-size: 0.7rem;
          margin-left: auto;
        }
      }
    }
  }
}
</style>