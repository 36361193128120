<template>
  <div>
    <GameplanLocker
      :dealerMode="dealerMode"
      :projectStatus="getSnapsheetStatus"
      @requestQuoteSucceded="SET_SNAPSHEET_STATUS(PROJECT_STATUS.QUOTE_STATUS)"
      @requestOmgStoresSuccess="SET_SNAPSHEET_STATUS(PROJECT_STATUS.OMG_STATUS)"
    />

    <router-link :to="routerLink" class="btn">Back to browsing</router-link>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import CONSTANTS from "@/constants.js";
export default {
  name: "LockerContainer",

  computed: {
    ...mapState(["lockerModule"]),
    ...mapGetters(["dealerMode"]),
    ...mapGetters("snapsheet", ["getSnapsheetStatus"]),

    routerLink() {
      const { dealer_code, snapsheet_id } = this.$route.params;
      return `/snapsheets/${dealer_code}/${snapsheet_id}/`;
    },

    PROJECT_STATUS() {
      return CONSTANTS.PROJECT_STATUS;
    },
  },

  methods: {
    ...mapMutations("snapsheet", ["SET_SNAPSHEET_STATUS"]),
  },
};
</script>
<style lang="scss" scoped>
.btn {
  background: #f9f9f9;
  font-size: 1rem;
  font-family: Ubuntu Bold;
  text-transform: uppercase;
  border: 1px solid #707070 !important;
  border-radius: 5px;
  padding: 0.2rem 2rem 0.4rem 2rem;
  margin: auto;
  display: block;
  width: 250px;
  margin-top: 2rem;

  box-shadow: 0 3px 6px -2px #707070;

  color: #252525;
  text-decoration: none;
  text-align: center;

  font-weight: bold;
  text-transform: uppercase;
  transition: box-shadow 0.2s ease;
  transition: background-color 0.15s ease;
  transition: color 0.15s ease;
  transition: transform 0.15s ease;

  &:hover {
    box-shadow: 0 0 8px 1px #00000099;
    background-color: black;
    color: white;

    transform: scale(1.05);
  }
}
</style>