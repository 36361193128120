import router from '@/router';
import Vue from 'vue'
import axios from 'axios'
import CONSTANTS from '@/constants.js';
//Apply -> Apply changes in editable product

//Save -> Save finals changes 

//Update -> Async calls to save changes

const snapsheet = {

  namespaced: true,

  state: () => ({
    actualPage: 1,
    customerId: '',
    color_1: '',
    decorationHasBeenEdited: false,
    editTitleActive: false,
    editableProduct: {},
    gender: "Men",
    logo: '',
    isEditModeActive: false,
    layoutId: '',
    lockerCode: '',
    pages: [],
    PER_PAGE: 3,
    products: [],
    productHasBeenEdited: false,
    projectTitle: '',
    repId: '',
    status: '',
    snapsheetId: '',
    snapsheetColorsIds: [5, 6, 7, 8, 10, 13, 14, 15, 16, 18, 19, 20, 21, 22, 23, 25],
    templateId: '',
    isStaticTemplate: false
  }),

  getters: {

    getSnapsheetStatus: state => state.status,

    isStaticTemplate: state => state.isStaticTemplate,

    productIsDuplicated: (state) => (productId) => {
      return state.products.some((savedProduct) => savedProduct.id == productId)
    },

    editableProductGridPrice: () => (product) => {

      const selfData = {
        alias: product.alias,
        gender: product.gender ? product.gender : product.genders[0],
        price: product.price,
        sizes: product.sizes,
        style_id: product.style_id,
      };

      if (product.related) {
        const otherGenders = product.related?.filter(genderData => genderData.style_id != product.style_id)?.map((genderData) => ({
          alias: genderData.alias,
          gender: genderData.gender ? genderData.gender : genderData.genders[0],//A veces viene en un array de una sola posicion, otras veces como string 👀
          price: Number(genderData.price).toFixed(2),
          sizes: genderData.sizes,
          style_id: genderData.style_id,
        }));

        otherGenders?.unshift(selfData);
        return otherGenders;
      } else {
        return [selfData]
      }

    },

    editableProductIsNew: (state) => {
      return Object.keys(state.editableProduct).length > 0 && state.editableProduct.id && !state.products.some(pr => pr.id == state.editableProduct.id)
    },

    editableProductIsEmpty: (state) => {
      return !!state.editableProduct.id
    },

    getProduct: (state) => (order) => {
      return Object.assign(state.products.filter(pr => pr.order === order)[0])
    },

    lockerCode: (state) => {
      return state.lockerCode
    }
  },

  actions: {

    async initSnapsheet({ dispatch, commit }) {
      const snapsheetId = router.currentRoute.params.snapsheet_id

      commit('setSnapsheetId', snapsheetId)
      //Get the snapsheet from endpoint
      try {
        await dispatch('getSnapsheet')
        await dispatch('calculatePages')

      } catch (error) {

        this._vm.$toastr.e('Error in initSnapsheet');

        console.log(error);
      }

    },

    async getSnapsheet({ rootState, state, commit, dispatch }) {
      const snapsheetId = state.snapsheetId
      const url = rootState.endpoint + `api/snapsheets/${snapsheetId}`
      const params = { dealer_code: rootState.dealer.rhino_code }

      try {
        const { data } = await axios.get(url, { params })

        commit('setSnapsheet', data)
        dispatch('setRep', data.rep, { root: true })

        const customerData = data.customer
        const name = data.title
        const colors = [data.color_1]
        const repId = data.rep_id

        // If there is customer Data in the response set it, otherwise create a new customer
        const setCustomerDataOrCreateCustomer = customerData && Object.keys(customerData).length
          ? () => dispatch('customer/initCustomer', customerData, { root: true })
          : () => dispatch('customer/createCustomer', { name, colors, type: 'travel team', repId }, { root: true })

        await setCustomerDataOrCreateCustomer();
      } catch (error) {
        console.log(error);

        this._vm.$toastr.e("Error getting snapsheet");

        router.push('/404')
      } finally {
        dispatch('triggerEndLoading', null, { root: true })
      }

    },

    async updateSnapsheetCustomerId({ rootState, state, commit }) {

      const params = {
        id: state.snapsheetId,
        _method: "put",
        customer_id: rootState.customer.id,
      }

      const url = rootState.endpoint + "api/snapsheets/" + state.snapsheetId;

      axios
        .post(url, params, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + rootState.dealer.api_token,
          },
        })
        .then(() => {

          commit('updateCustomerId', rootState.customer.id)
        })
        .catch((e) => {
          console.log(e);
        });

    },

    async updateSnapsheetStatus({ rootState, state, commit }, status) {
      try {
        const params = {
          id: state.snapsheetId,
          _method: "put",
          status
        }

        const url = rootState.endpoint + "api/snapsheets/" + state.snapsheetId;

        let config = {
          headers: {
            Authorization: 'Bearer ' + rootState.dealer.api_token
          },
        }

        await axios.put(url, params, config)

        commit('SET_SNAPSHEET_STATUS', status)

      } catch (error) {
        throw new Error(error)
      }
    },

    async saveProduct({ rootState, state, dispatch }) {
      const snapsheetId = state.snapsheetId
      let url = rootState.endpoint + `api/snapsheetproducts`

      const { id, price, order, related, wrapperId } = state.editableProduct
      const relatedPrices = related && related.map(genderProduct => ({ style_id: genderProduct.style_id, price: genderProduct.price }))

      let params = {
        snapsheet_id: snapsheetId,
        product_id: id,
        price: Number(price),
        order,
      }

      let settings = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + rootState.dealer.api_token,
        }
      }
      //If the product has related prices
      if (relatedPrices) {
        params['related_prices'] = relatedPrices
      }

      try {

        //Edit
        if (state.productHasBeenEdited) {
          params['_method'] = 'put'
          url = url + '/' + wrapperId
          const { data } = await axios.put(url, params, settings)

          dispatch('setProduct', data.id)

        } else {

          //Create or Swap
          const { data } = await axios.post(url, params, settings)
          const wrapperId = data.id

          await dispatch('setProduct', wrapperId)

          //We need to call '/decorations' to update and save a new layout ID, otherwise it will inherit the layoutId from the previous product

          //(Only if the new product has layouts)
          if (state.editableProduct.layouts?.length > 0) {

            const layoutData = {
              mode: 2,
              pageId: this.pageId,
              productId: wrapperId,
            }

            await dispatch('updateDecorations', layoutData)

          }
        }
      } catch (error) {
        this._vm.$toastr.e("Error in saveProduct");
        console.log(error)
      }
    },

    setProduct({ commit, dispatch, state }, wrapperId) {

      const product = {
        order: state.editableProduct.order - 1,
        product: {
          ...state.editableProduct,
          price: Number(state.editableProduct.price).toFixed(2),
          wrapperId: wrapperId != null ? wrapperId : state.editableProduct.wrapperId //This happens when the user select a new product. We need to set the WrapperId that came from the backend response
        }
      }

      commit('setProduct', product)


      dispatch('calculatePages')
    },

    async addToLocker({ rootState, dispatch }, { wrapperId, variantId, lockerCode }) {
      // const snapsheetId = state.snapsheetId
      let url = rootState.endpoint + `api/snapsheetproducts/${wrapperId}/add-to-locker`

      let params = {
        variant_index: variantId,
        locker_code: lockerCode
      }

      let settings = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + rootState.dealer.api_token,
        }
      }

      try {
        dispatch('lockerModule/setLoadingLockerState', true, { root: true })
        const { data } = await axios.post(url, params, settings)
        const lockerCode = data
        await dispatch('lockerModule/getLockerByCode', lockerCode, { root: true })

      } catch (error) {

        this._vm.$toastr.e('error in addToLocker');
        console.log(error)
        throw new Error
      }
    },

    async updateSnapsheetTitle({ rootState, state, dispatch, commit }, payload) {
      dispatch('triggerStartLoading', null, { root: true })
      //axios post
      const snapsheetId = state.snapsheetId
      const url = rootState.endpoint + `api/snapsheets/${snapsheetId}`
      let settings = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + rootState.dealer.api_token,
        }
      }

      let params = {
        title: payload.title,
      }

      try {
        await axios.put(url, params, settings)
        commit("saveSnapsheetTitle", {
          title: payload.title
        })
      } catch (error) {
        this._vm.$toastr.e('Error in updateSnapsheetTitle');

      } finally {
        dispatch('triggerEndLoading', null, { root: true })

      }
    },

    async updateProductVariantColor({ rootState, rootGetters, dispatch }, { wrapperId, order, selectedColorId }) {
      try {

        const url = rootState.endpoint + `api/snapsheetproducts/` + wrapperId

        let settings = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + rootState.dealer.api_token,
          }
        }

        let params = {
          variant_id: selectedColorId,
        }

        await dispatch('saveProductVariantColor', { order, selectedColorId })

        //Call to the API to apply this change only in dealer mode or when the project is not locked
        !rootGetters['actionsPermissions/isProjectLocked'] && await axios.put(url, params, settings)
      } catch (error) {
        this._vm.$toastr.e("Error in updateProductVariantColor");
        console.log(error);

      } finally {
        // dispatch('triggerEndLoading', null, { root: true })

      }
    },

    async saveProductVariantColor({ getters, commit, dispatch }, { order, selectedColorId }) {
      return new Promise((resolve, reject) => {

        const product = getters.getProduct(order)
        product.variantId = selectedColorId
        product.design = selectedColorId ? product.variants.filter(variant => variant.color_id == selectedColorId)[0] : product.variants[0]

        commit('setProduct', { order: order - 1, product })
        dispatch('changeEditMode', false)
          .then(() => resolve())
          .catch(e => reject(e))

      })
    },

    async updateFeaturedColor({ rootState, rootGetters, state, dispatch }, payload) {

      try {
        const selectedColorId = payload.color.color.id

        dispatch('triggerStartLoading', null, { root: true })


        if (!rootGetters['actionsPermissions/isProjectLocked']) {

          const snapsheetId = state.snapsheetId
          const url = rootState.endpoint + `api/snapsheets/action/featured-color/${snapsheetId}`


          let settings = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + rootState.dealer.api_token,
            }
          }

          let params = {
            color_1: selectedColorId,
            wrapper_ids: payload.wrapperIds,
          }

          await axios.put(url, params, settings)
          // await dispatch("initSnapsheet");
        }

        await dispatch('saveFeaturedColor', selectedColorId)
      } catch (error) {

        this._vm.$toastr.e("Error trying to update featured color");

      } finally {
        dispatch('triggerEndLoading', null, { root: true })

      }

    },

    saveFeaturedColor({ state, getters, dispatch, commit }, selectedColorId) {
      // Save the products's color in order
      state.products.reduce(async (previousPromise, actProduct) => {
        try {
          await previousPromise;

          return new Promise((resolve, reject) => {

            const product = getters.getProduct(actProduct.order)
            const productDesignsMatchedByColorFamily = product.variants?.filter(variant => variant.color_family == selectedColorId)
            const finalDesignSelected = productDesignsMatchedByColorFamily.length ? productDesignsMatchedByColorFamily[0] : product.variants[0]
            product.design = finalDesignSelected
            product.variantId = finalDesignSelected.color_id

            commit('setProduct', { order: actProduct.order - 1, product })
            dispatch('changeEditMode', false)
              .then(() => resolve())
              .catch(e => reject(e))

          })
        } catch (e) {
          // console.log(e);
          Promise.reject()
          throw new Error('error saving featured color')
        }
      }, Promise.resolve())

      commit('SET_FEATURED_COLOR', selectedColorId)
    },

    async updateSnapsheetGender({ rootState, state, dispatch }, payload) {
      dispatch('triggerStartLoading', null, { root: true })
      //axios post
      const snapsheetId = state.snapsheetId
      const url = rootState.endpoint + `api/snapsheets/action/change-gender/${snapsheetId}`

      let settings = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + rootState.dealer.api_token,
        }
      }
      let params = {
        gender: payload.replace("'s", "")
      }

      try {
        await axios.put(url, params, settings)
        await dispatch("initSnapsheet");

      } catch (error) {

        this._vm.$toastr.e("Error in updateSnapsheetGender");

      } finally {
        dispatch('triggerEndLoading', null, { root: true })
      }

    },

    async updateDecorations({ rootState, state, rootGetters, dispatch }, { mode, pageId, productId, logoHasChanged }) {

      try {

        let productIds = [];

        switch (parseInt(mode)) {
          //Apply in complete Catalog
          case 0: {
            for (let index = 0; index < state.pages.length; index++) {
              const page = state.pages[index];

              productIds = productIds.concat(
                page?.filter((item) => {
                  return item.wrapperId != null;
                }).map((item) => {
                  return item.wrapperId;
                })
              );
            }
            break;
          }

          //Apply only in actual page
          case 1: {
            const currentPage = state.pages[pageId - 1];
            productIds = currentPage.filter((item) => {
              return item.wrapperId != null;
            }).map((item) => {
              return item.wrapperId;
            });
            break;
          }

          //Apply only in the actual product 
          case 2: {
            productIds = [productId]; //productId = wrapperId
            break;
          }
        }

        const individualUpdateURL = rootState.endpoint + `api/snapsheetproducts/` + productId
        const updateLogosURL = rootState.endpoint + `api/snapsheetproducts/bulk-update`

        //update layout of the selected style regardless of the radio button

        let params = {
          layout_id: state.editableProduct.activeLayoutId,
        };

        let settings = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + rootState.dealer.api_token,
          }
        };

        //Check if the project is not locked
        if (!rootGetters['actionsPermissions/isProjectLocked']) {

          //update layout
          await axios.put(individualUpdateURL, params, settings);


          //update logo

          if (logoHasChanged) {
            params = {
              wrapper_ids: productIds,
              logo_id: state.editableProduct.logoId ? state.editableProduct.logoId : null,
            };

            if (state.editableProduct.logoId) {
              params['logo_content'] = state.editableProduct.screenLogo?.logo,
                params['logo_settings'] = {
                  position: state.editableProduct.screenLogo?.position,
                  zoom: state.editableProduct.screenLogo?.zoom
                }
            }

            await axios.put(updateLogosURL, params, settings);
          }


        }

        await dispatch('saveDecorationsData', {
          productIds,
          logoId: state.editableProduct.logoId,
          screenLogo: state.editableProduct.screenLogo,
          activeLayout: state.editableProduct.activeLayoutId,
          layoutProductId: productId, //update layout only from current style
        })

        await dispatch('changeDecorationHasBeenEdited', true)
      } catch (error) {
        console.log(error);
        this._vm.$toastr.e("Error trying to update product decorations");
      }
    },

    saveDecorationsData({ state, commit }, payload) {

      [...state.products].map(product => {
        if (payload.productIds.includes(product.wrapperId)) {
          //update logo if it changed for the given wrapperid
          product.logo = payload.logo;
          product.logoId = payload.logoId;
          product.screenLogo = payload.screenLogo;
          //Todo. Create actions to save original logo and screen logo

          //update selected layout only on current style
          if (payload.layoutProductId === product.wrapperId) {
            product.activeLayoutId = payload.activeLayout;
            commit('setProduct', { order: product.order - 1, product })
          }
        }
      })
    },

    async deleteProduct({ rootState, state, dispatch }, orderId) {
      dispatch('triggerStartLoading', null, { root: true })
      //axios delete
      const snapsheetId = state.snapsheetId
      const url = rootState.endpoint + `api/snapsheets/snapsheet/${snapsheetId}/action/delete-product`
      let settings = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + rootState.dealer.api_token,
        }
      }
      let params = {
        order: orderId
      }

      try {
        await axios.post(url, params, settings)
        await dispatch("initSnapsheet");

      } catch (error) {
        this._vm.$toastr.e('Error in deleteProduct');
      } finally {
        dispatch('triggerEndLoading', null, { root: true })

      }

    },


    //Editable Product actions

    setEditableProduct({ commit }, { product, order, logoId, screenLogo }) {
      product.variantId = product.variantId || product.variant_id
      commit('setEditableProduct', { product, order, logoId, screenLogo })
    },

    applyEditableProductPrices({ state, dispatch, commit, getters }, { value, styleId }) {
      //The price belongs to the main product
      if (state.editableProduct.style_id == styleId) {
        commit('applyMainProductPrice', value)
      } else {
        //Find the index of the price that is being edited
        const index = state.editableProduct.related.findIndex(genderProd => genderProd.style_id == styleId)
        commit('applyRelatedProductPrice', { index, value })
      }

      //Let clear that the product has been edited, only when the product that is being edited isn't new
      !getters.editableProductIsNew && dispatch('changeProductHasBeenEdited', true)
    },

    applyChangeOriginalLogo({ dispatch, commit, rootGetters }, logoId) {
      const logo = rootGetters['customer/getLogoById'](logoId);

      dispatch('applyScreenLogo', { logo: logo.content }).then(() => commit('applyChangeOriginalLogo', logoId))

    },

    applyScreenLogo({ commit }, logoScreenProperties) {
      const logoScreenObj = {
        logo: logoScreenProperties?.logo || null, zoom: logoScreenProperties?.zoom || CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.zoom, position: logoScreenProperties?.position || CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.position
      }
      commit('applyScreenLogo', logoScreenObj)
    },

    applyRemoveLogo({ commit, dispatch }) {
      commit('applyChangeOriginalLogo', null)
      dispatch('applyScreenLogo', null) //send a empty payload 
    },

    changeEditMode({ commit, dispatch }, bool) {

      commit('setEditMode', bool)

      //To disable scroll in mobiles
      if (window.innerWidth <= 768 && bool === true) {
        document.body.style.overflow = 'hidden'
      }

      //Clear the editable product
      if (bool === false) {
        commit('setEmptyEditableProduct')
        dispatch('changeProductHasBeenEdited', false)

        //To able scroll in mobiles

        document.body.style.overflow = 'initial'

      }

    },

    changeProductHasBeenEdited({ commit }, bool) {
      commit('setProductHasBeenEdited', bool);
    },

    changeDecorationHasBeenEdited({ commit }, bool) {
      commit('setDecorationHasBeenEdited', bool);
      commit('setEditMode', !bool);
    },

    toggleEditTitleActive({ commit }) {
      commit('toggleEditTitleActive');
    },

    calculatePages({ rootGetters, state, commit }) {

      //The user is creating from scratch
      const arrWithSpaces = new Array(Number(state.layoutId ? state.layoutId : 6)).fill({})

      //Set the previous products in order
      state.products.forEach(pr => {
        arrWithSpaces[Number(pr.order) - 1] = pr
      })

      //If Dealer mode is active, add an extra space by adding a new empty space
      if (rootGetters['dealerMode']) {
        const isThereAnyEmptySpace = arrWithSpaces.find(space => !space.id)
        if (!isThereAnyEmptySpace) arrWithSpaces.push({})
      } else {
        //Eliminate the last empty space in Coach mode
        const lastSpace = arrWithSpaces[arrWithSpaces.length > 0 ? arrWithSpaces.length - 1 : 0]
        if (!lastSpace.id) {
          arrWithSpaces.pop()
        }
      }

      //Set an order Id for each space
      const spacesWithOrder = arrWithSpaces.map((space, i) => ({ ...space, order: space.order ? space.order : i + 1 }))

      const arrayOfProductsPages = spacesWithOrder.map((_item, index) => {
        return index % state.PER_PAGE === 0 ? spacesWithOrder.slice(index, index + state.PER_PAGE) : null;
      })
        .filter(function (item) {
          return item;

        });

      //Si vienen 3 productos o menos, seria una sola pagina
      if (spacesWithOrder.length <= state.PER_PAGE) {
        const unicPage = [[...spacesWithOrder.map(pr => pr)]]
        commit('setPages', unicPage)
      } else {
        commit('setPages', arrayOfProductsPages)
      }

      commit('setProducts', arrayOfProductsPages.flat())

    },

    setPage({ commit }, number) {
      commit('setPage', number);
    }
  },

  mutations: {
    setSnapsheetId: (state, id) => state.snapsheetId = id,

    setSnapsheet: (state, snapsheetData) => {
      state.customerId = snapsheetData.customer?.id
      state.color_1 = snapsheetData.color_1
      state.gender = snapsheetData.gender
      state.logo = snapsheetData.logo
      state.layoutId = snapsheetData.layout
      state.lockerCode = snapsheetData.locker_code
      state.projectTitle = snapsheetData.title
      state.products = snapsheetData.products.map(pr => ({
        ...pr.product,
        design: pr.variant_id ? pr.product.variants.filter(variant => variant.color_id == pr.variant_id)[0] : pr.product.variants[0],
        order: pr.order,
        sizes: pr.product.sizes,
        price: Number(pr.price).toFixed(2),
        related: pr.related,
        wrapperId: pr.id,
        logoId: pr.logo?.id, //Main logo Id
        //The modified original logo with its own properties
        screenLogo: {
          logo: pr.logo_url || null,
          position: pr.logo_settings?.position || CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.position,
          zoom: pr.logo_settings?.zoom || CONSTANTS.DEFAULT_SCREEN_LOGO_PROPERTIES.zoom,
        },
        activeLayoutId: pr.active_layout || pr.product.layouts[0]?.id,
        variantId: pr.variant_id || pr.product.variants[0].color_id,
        wasAddedToLocker: false,
      }))
      state.repId = snapsheetData.rep_id
      state.status = snapsheetData.status || 'open'
      state.snapsheetId = snapsheetData.id
      state.templateId = snapsheetData.template_id
      // state.isStaticTemplate = snapsheetData.is_static_template || false
    },

    SET_SNAPSHEET_STATUS: (state, status) => { state.status = status },

    updateCustomerId: (state, customerId) => { state.customerId = customerId },

    setPages: (state, pages) => {
      state.pages = pages
    },

    setProducts: (state, products) => {
      state.products = products
    },

    setEditableProduct: (state, { product, order, logoId, screenLogo }) => {
      const editableProduct = {
        ...product,
        activeLayoutId: product.layouts[0]?.id,
        logoId: product.logoId || state.editableProduct.logoId || logoId,
        screenLogo,
        order,
        design: product.variantId ? product.variants.filter(variant => variant.color_id == product.variantId)[0] : product.variants[0],
        sizes: product.sizes,
        price: Number(product.price).toFixed(2),
        related: product.related,
        wrapperId: product.wrapperId != null ? product.wrapperId : product.wrapper_id,
        variantId: product.variantId || product.variants[0].color_id
      }

      Vue.set(state, 'editableProduct', editableProduct)

    },

    setEmptyEditableProduct: (state) => {

      state.editableProduct = {}
    },

    applyMainProductPrice: (state, value) => {
      state.editableProduct.price = value
    },

    applyRelatedProductPrice: (state, { index, value }) => {
      state.editableProduct.related[index].price = value
    },

    applyChangeOriginalLogo: (state, logoId) => {
      const updatedEditableProduct = { ...state.editableProduct, logoId }
      Vue.set(state, 'editableProduct', updatedEditableProduct)
    },

    applyScreenLogo: (state, screenLogoProperties) => {
      const updatedEditableProduct = { ...state.editableProduct, screenLogo: screenLogoProperties }
      Vue.set(state, 'editableProduct', updatedEditableProduct)
    },

    applyActiveLayoutId: (state, layoutId) => {
      state.editableProduct.activeLayoutId = layoutId;
    },

    setProduct: (state, { order, product }) => {
      Vue.set(state.products, order, product)
    },

    saveDecorationsData: (state, payload) => {
      state.products.forEach(product => {
        if (payload.productIds.includes(product.wrapperId)) {
          //update logo if it changed for the given wrapperid
          if (payload.logo) product.logo = payload.logo;
          //update selected layout only on current style
          if (payload.layoutProductId === product.wrapperId) {
            product.activeLayoutId = payload.activeLayout;
          }
        }
      })
    },

    setEditMode: (state, bool) => {
      state.isEditModeActive = bool //boolean
    },

    setProductHasBeenEdited: (state, bool) => {
      state.productHasBeenEdited = bool //boolean
    },

    setDecorationHasBeenEdited: (state, bool) => {
      state.decorationHasBeenEdited = bool //boolean
    },

    setSnapsheetColor: (state, payload) => {
      state[payload.field] = payload.color.color.id;
    },

    toggleEditTitleActive: (state) => {
      state.editTitleActive = !state.editTitleActive;
    },

    saveSnapsheetTitle: (state, payload) => {
      state.projectTitle = payload.title;
    },

    setPage: (state, page) => {
      state.actualPage = page;
    },

    SET_FEATURED_COLOR: (state, colorSelectedId) => {
      state.color_1 = colorSelectedId
    }
  },
}

export default snapsheet