import axios from 'axios'


const customer = {

  namespaced: true,

  state: () => ({
    id: '',
    name: '',
    type: '',
    rep: '',
    colors: [],
    projects: [],
    logos: [],
    created: '',
    school: '',
    status: ''
  }),

  getters: {
    getLogoById: (state) => (selectedLogoId) => {
      return state.logos.filter(logo => logo.id == selectedLogoId)[0]
    },

    customerId: (state) => state.id,

    customerName: (state) => state.name
  },

  actions: {

    // Customer CRUD

    async initCustomer({ dispatch, commit }, customerData) {
      commit('updateCustomer', customerData)
      const normalizedLogos = await dispatch('normalizeLogos', customerData.logos)
      commit('updateLogos', normalizedLogos)
    },

    async createCustomer({ rootState, commit }, { name, colors, type, repId }) {
      const url = rootState.endpoint + "api/customers";

      let params = {
        colors,
        name,
        type,
        rep_id: repId
      };

      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + rootState.dealer.api_token,
        },
      };

      await axios
        .post(url, params, headers)
        .then(({ data }) => {

          commit('updateCustomer', data)

        })
        .catch((e) => {
          console.log(e);
        });
    },

    async updateCustomer({ rootState, dispatch, commit }, { name, colors, type, repId, logos }) {
      const url = rootState.endpoint + "api/customers";

      let params = {
        colors: [colors[0].id, colors[1].id, colors[2].id],
        name,
        type,
        rep_id: repId,
      };

      if (Object.keys(colors[3]).length) {
        params.colors.push(colors[3].id);
      }

      if (Object.keys(colors[4]).length) {
        params.colors.push(colors[4].id);
      }

      if (Object.keys(colors[5]).length) {
        params.colors.push(colors[5].id);
      }

      if (logos.length) {
        params["logos_ids"] = logos.map((logo) => logo.id);
      }

      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + rootState.dealer.api_token,
        },
      };

      await axios
        .post(url, params, headers)
        .then(async (response) => {

          commit('updateCustomer', response.data)
          const normalizedLogos = await dispatch('normalizeLogos', response.data.logos)
          commit('updateLogos', normalizedLogos)

          //Update the customer basic data that is in rootState.dealer.customers
          dispatch('updateBasicCustomer', response.data, { root: true })

          this._vm.$toastr.s("Edited successfully");

          this.$emit("updateCustomer", response.data);
        })
        .catch(() => {

          this._vm.$toastr.e("Error trying to update the customer");
        });
    },

    async updateCustomerStatus({ rootState, commit }, { customerId, status }) {

      // this.requestDelete = true;
      // console.log(status);

      axios
        .put(
          rootState.endpoint + `api/customers/${customerId}`,
          { status },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + rootState.dealer.api_token,
            },
          }
        )
        .then(() => {

          commit('updateCustomerStatus', status)

          // this.$emit("changeCustomerStatus", { id: this.customer.id, status });

          this._vm.$toastr.s(status == "archived" ? "Customer Archived" : "Customer Unarchived")

          // this.requestDelete = false;
        })
        .catch(() => {

          this._vm.$toastr.e("Error trying to update customer status")
          this.requestDelete = false;
        });
    },

    // Logos CRUD

    addNewLogo({ commit }, logoData) {
      commit('addNewLogo', logoData)
    },

    updateLogo({ commit }, logoData) { commit('updateLogo', logoData) },

    udpateLogoStatus() { console.log('hey'); },

    normalizeLogos({ state }, logos) {
      console.log(state.id);
      const normalizedLogos = logos
        .filter(logo => logo.status !== 'archived')
        .map((logo) => ({
          ...logo,
          fileUploaded: logo.content,
          fileOriginal: logo.url,
          typeOfLogo: logo.type ? logo.type : [],
        }));
      return normalizedLogos

    },


  },
  mutations: {

    updateCustomer: (state, updatedCustomer) => {

      const {
        id,
        created,
        colors,
        logos,
        name,
        projects,
        rep,
        school,
        status,
        type, } = updatedCustomer


      // Colors
      const newColors = [];
      newColors[0] = colors[0];
      newColors[1] = colors[1];
      newColors[2] = colors[2];
      colors[3]
        ? (newColors[3] = colors[3])
        : false;
      colors[4]
        ? (newColors[4] = colors[4])
        : false;
      colors[5]
        ? (newColors[5] = colors[5])
        : false;

      // Logos
      state.logos = logos

      state.id = id
      state.created = created
      state.colors = newColors
      state.logos = logos
      state.name = name
      state.projects = projects ? projects : []
      state.school = school
      state.status = status
      state.rep = rep
      state.type = type
    },

    addNewLogo: (state, newLogo) => { state.logos.push(newLogo) },

    updateLogo: (state, logoData) => {
      state.logos = [...state.logos].map((logo) => {
        if (logo.id === logoData.editedLogoId) {
          logo.fileUploaded = logoData.editedLogo;
          logo.content = logoData.editedLogo;
          logo.typeOfLogo = logoData.typeOfLogo;
          logo.colors = logoData.colors;
        }

        return logo;
      });
    },

    updateLogos: (state, logos) => {
      state.logos = logos
    },

    updateCustomerStatus: (state, status) => {
      state.status = status
    }
  },
}

export default customer