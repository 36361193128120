<template>
  <div
    class="wrapper d-flex align-items-center justify-center m-auto full-width"
  >
    <!-- Manage Logos -->

    <ManageLogosModal
      v-show="showManageLogosModal && !loading"
      :preselectedLogoId="preselectedLogoId"
      :uploadedLogos="logos"
      :MAX_LOGOS_ALLOWED="1"
      @changeSelectedLogo="handleChangeSelectedLogo"
      @removeLogo="handleRemoveLogo"
      @closeModal="showManageLogosModal = false"
      @uploadingLogo="handleUploadingLogo"
      @logoHasBeenUploaded="handleLogoHasBeenUploaded"
    />

    <UploadAndEditLogoModal
      v-show="showUploadAndEditLogoModal"
      :fileUploaded="fileUploaded"
      :fileName="fileName"
      :fileSize="fileSize"
      :fileExtension="fileExtension"
      :fileOriginal="fileOriginal"
      :customerId="customerId"
      :logoIdToBeEdited="logoIdToBeEdited"
      :editableLogo="editableLogo"
      :typesOfLogoToBeEdited="typesOfLogoToBeEdited"
      :isVector="isVector"
      :isManageModalOpen="showManageLogosModal"
      @close="showUploadAndEditLogoModal = false"
      @saveEditedLogo="saveEditedLogo"
    />

    <transition name="fade">
      <EditScreenLogoModal
        v-show="showEditScreenLogoModal"
        @applyScreenLogo="handleApplyScreenLogo"
        @close="showEditScreenLogoModal = false"
      />
    </transition>

    <!-- Card -->
    <article
      class="snap-card decorations-snap-card"
      v-show="!showManageLogosModal && !loading"
    >
      <div v-if="isEditModeActive" class="close-card-container">
        <button class="close" @click="() => changeEditMode(false)">
          <span>X</span>
        </button>
      </div>

      <div class="snap-card-body">
        <!-- Vector -->
        <div class="vector-container" v-if="!productIsEmpty">
          <h5>{{ product.name }}</h5>
          <Vector
            :side="'front'"
            :svgs="svgs"
            :backgrounds="backgrounds"
            :layout="productLayout"
            :is_stock="!product.is_sublimable"
            :isVariantStock="!product.is_sublimable"
            :stamp_logo="stamp_logo"
          />
        </div>

        <!-- Graphics -->
        <div class="graphic-container">
          <h5>Custom graphic</h5>
          <div class="graphic-container">
            <div class="img-container" @click="openEditScreenLogoModal">
              <ScreenLogoPreview
                :showLogoCondition="originalLogo"
                :logo="screenLogo"
                :loading="loading"
                @handleReplaceOrDeleteButton="openEditScreenLogoModal"
              />
            </div>

            <button
              v-if="stamp_logo"
              class="edit-graphic"
              @click="openEditScreenLogoModal"
            >
              Move / Resize
            </button>

            <button class="btn-liketext" @click="toggleManageLogosModal()">
              <SwapIconSVG />
              <span>
                {{ originalLogoImage ? "Swap Graphic" : "New graphic" }}</span
              >
            </button>

            <input
              type="file"
              id="file-graphic"
              @click="$event.target.value = ''"
              @change="uploadLogo()"
              ref="file"
            />
          </div>
        </div>

        <!-- Layouts  -->
        <div class="layout-buttons-container">
          <h5>{{ stamp_logo ? "Location" : "" }}</h5>
          <SnapsheetDecorationLayoutOption
            v-show="stamp_logo"
            v-for="layout in layouts"
            :key="product.style_id + '-' + layout.id"
            @applyActiveLayout="handleApplyActiveLayoutId"
            :activeLayout="activeLayout"
            :layout="layout"
          />
        </div>
      </div>

      <!-- Save Changes -->
      <div v-if="edited" class="snap-card-footer">
        <button
          class="decorations-save-button"
          @click="saveDecorationChanges()"
        >
          Save changes
        </button>
        <div class="save-decorations-options">
          <label>Apply to:</label>
          <div class="radio-buttons-container">
            <div class="radio-button-container">
              <label for="decoration-radio-0">Complete Catalog</label>
              <input
                id="decoration-radio-0"
                class="radio-button"
                type="radio"
                value="0"
                v-model="mode"
              />
            </div>
            <div class="radio-button-container">
              <label for="decoration-radio-1">This Page Only</label>
              <input
                id="decoration-radio-1"
                class="radio-button"
                type="radio"
                value="1"
                v-model="mode"
              />
            </div>
            <div class="radio-button-container">
              <label for="decoration-radio-2">This Style Only</label>
              <input
                id="decoration-radio-2"
                class="radio-button"
                type="radio"
                value="2"
                v-model="mode"
              />
            </div>
          </div>
        </div>
      </div>
    </article>

    <!-- Loader -->
    <article
      class="snap-card decorations-snap-card loading-snap-card"
      v-if="loading"
    >
      <Loader />
    </article>
  </div>
</template>

<script>
import SnapsheetDecorationLayoutOption from "@/components/Snapsheet/SnapsheetDecorationLayoutOption.vue";
import ManageLogosModal from "@/components/Logos/ManageLogosModal.vue";
import ScreenLogoPreview from "@/components/Logos/ScreenLogoPreview";
import UploadAndEditLogoModal from "@/components/Logos/UploadAndEditLogoModal.vue";
import EditScreenLogoModal from "@/components/Logos/EditScreenLogoModal.vue";
import SwapIconSVG from "@/components/SwapIconSVG.vue";
import Vector from "@/components/Vector.vue";
import Loader from "@/components/Loader.vue";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "SnapsheetPageItemDecorations",
  components: {
    Vector,
    SnapsheetDecorationLayoutOption,
    ManageLogosModal,
    ScreenLogoPreview,
    UploadAndEditLogoModal,
    EditScreenLogoModal,
    Loader,
    SwapIconSVG,
  },
  props: [
    "originalProduct",
    "editableProduct",
    "pageId",
    "arrayIndex",
    "showSaveChangesButton",
    "isUniqEditMode",
    "originalLogo",
    "originalLogoImage",
    "screenLogoImage",
    "layouts",
  ],

  data() {
    return {
      edited: false,
      showManageLogosModal: false,
      showUploadAndEditLogoModal: false,
      showEditScreenLogoModal: false,
      mode: 2,

      activeLayout: 0,
      loading: false,

      //Edit Logo
      logoHasChanged: false,
      fileUploaded: "",
      fileOriginal: "",
      fileName: "",
      fileExtension: "",
      fileSize: "",
      logoSrc: "",
      editableLogo: "",
      logoIdToBeEdited: "",
      typesOfLogoToBeEdited: [],
      isVector: false,
    };
  },

  computed: {
    ...mapState(["dealer", "endpoint"]),
    ...mapState("snapsheet", ["logo", "isEditModeActive"]),
    ...mapState("customer", ["logos"]),
    ...mapGetters("customer", ["getLogoById", "customerId"]),

    product() {
      return this.isUniqEditMode ? this.editableProduct : this.originalProduct;
    },

    screenLogo() {
      return { ...this.product.screenLogo, id: this.originalLogo?.id };
    },

    stamp_logo() {
      //returns the editable product's screen logo, or the original logo (support old snapsheets)
      if (!this.productLayout) return false;
      return this.product.screenLogo?.logo;
    },

    designs() {
      return this.product.variants; //this will change when we use sublimables
    },

    productLayout() {
      return this.product.activeLayoutId
        ? this.layouts.filter((layout) => {
            return layout.id === this.product.activeLayoutId;
          })[0]
        : this.product.layouts[0];
    },

    productIsEmpty() {
      return !this.product.id;
    },

    preselectedLogoId() {
      return this.product.logoId;
    },

    svgs() {
      return this.product.design
        ? this.product.design
        : { group1: [], group2: [], group3: [] };
    },

    backgrounds() {
      return this.svgs.backgrounds;
    },
  },

  methods: {
    ...mapActions("snapsheet", [
      "updateDecorations",
      "changeEditMode",
      "applyChangeOriginalLogo",
      "applyScreenLogo",
      "applyRemoveLogo",
    ]),
    ...mapActions("customer", ["addNewLogo", "updateLogo"]),
    ...mapActions("screenLogo", ["setScreenLogoProperties"]),

    //--Editable Product methods

    handleChangeSelectedLogo(selectedLogoId) {
      this.logoIdToBeEdited = selectedLogoId;
      this.showManageLogosModal = false;
      this.logoHasChanged = true;
      this.edited = true;
      this.applyChangeOriginalLogo(selectedLogoId);
    },

    //This only shows how the screen will be, and it is applied in the editableProduct, so it does not affect the original product unless the user save changes
    handleApplyScreenLogo(setScreenLogoProperties) {
      this.logoSrc = setScreenLogoProperties.logo;
      this.logoHasChanged = true;
      this.edited = true;
      this.showEditScreenLogoModal = false;
      this.applyScreenLogo(setScreenLogoProperties);
    },

    handleRemoveLogo() {
      this.logoHasChanged = true;
      this.showManageLogosModal = false;
      this.edited = true;
      this.applyRemoveLogo();
    },

    handleApplyActiveLayoutId() {
      this.edited = true;
    },

    handleUploadingLogo(file) {
      this.showUploadAndEditLogoModal = true;
      // this.showManageLogosModal = false;
      this.fileUploaded = "";
      this.fileOriginal = "";
      this.file = file;
      this.fileName = this.file.name;
      this.fileExtension = this.fileName.substring(
        this.fileName.lastIndexOf(".") + 1
      );

      let fSExt = new Array("Bytes", "KB", "MB", "GB"),
        i = 0,
        _size = this.file.size;
      while (_size > 900) {
        _size /= 1024;
        i++;
      }
      this.fileSize = Math.round(_size * 100) / 100 + " " + fSExt[i];
    },

    handleLogoHasBeenUploaded(newLogo) {
      this.fileUploaded = newLogo.fileUploaded;
      this.fileOriginal = newLogo.fileOriginal;
      this.logoIdToBeEdited = newLogo.id;
      this.addNewLogo(newLogo);
      this.applyChangeOriginalLogo(newLogo.id);
    },

    openEditScreenLogoModal() {
      if (this.editableProduct?.logoId) {
        this.showEditScreenLogoModal = true;

        const logoBase64 = this.getLogoById(this.logoIdToBeEdited);
        const { zoom, position } = this.editableProduct.screenLogo;
        this.setScreenLogoProperties({
          originalGraphic: logoBase64.content,
          zoom,
          position,
        });
      }
    },

    //-- Save product (logos/layouts) changes

    async saveDecorationChanges() {
      try {
        this.loading = true;
        await this.updateDecorations({
          mode: this.mode,
          pageId: this.pageId,
          productId: this.product.wrapperId,
          logoHasChanged: this.logoHasChanged,
        });
        this.changeEditMode(false);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    saveEditedLogo({ editedLogoId, editedLogo, typeOfLogo, colors }) {
      this.fileUploaded = "";
      this.fileOriginal = "";
      this.product.logoId = editedLogoId;
      this.updateLogo({ editedLogoId, editedLogo, typeOfLogo, colors });

      this.showManageLogosModal = true;
    },

    triggerLogoInput() {
      document.getElementById("file-graphic").click();
    },

    toggleManageLogosModal() {
      this.showManageLogosModal = !this.showManageLogosModal;
    },
  },

  watch: {
    logoIdToBeEdited(_newValue, oldValue) {
      if (oldValue !== "") this.logoHasChanged = true;
    },
  },

  mounted() {
    this.activeLayout = this.product.activeLayoutId
      ? this.product.activeLayoutId
      : this.product.layouts[0].id;

    this.logoIdToBeEdited = this.editableProduct.logoId || null;
  },
};
</script>

<style lang="scss" scoped>
#app {
  .wrapper {
    .decorations-snap-card {
      flex-direction: column;
      justify-content: flex-start;
      padding: 0;
      background-color: #fff;
      flex-wrap: nowrap;
      position: relative;
      padding: 1rem;

      .close-card-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;
        button {
          height: 30px;
          width: 30px;
          background: rgb(12, 12, 12);
          border: none;
          color: white;
          border-radius: 5px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .snap-card-header {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 5px 0px;
      }

      .snap-card-body {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        background-color: white;
        width: 100%;
        padding: 0.4rem 1rem;

        h5 {
          text-align: center;
          text-transform: uppercase;
          margin-bottom: 1rem;
        }

        .vector-container {
          display: flex;
          justify-content: center;
        }

        .graphic-container {
          //width: 30%;
          display: flex;
          flex-flow: column;
          align-items: center;
          #file-graphic {
            display: none;
          }
          .edit-graphic {
            cursor: pointer;
            font-size: 12px;
            color: #666;
            margin: 8px auto;
            background: none;
            padding: 0.4rem;
            border: 1px solid #666;
            width: 100%;

            &:hover {
              background: black;
              color: white;
              transform: scale(1);
            }
          }
          .img-container {
            width: 100px;
            height: 100px;
            margin-bottom: .5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);
            cursor: pointer;
            transition: all 0.2s;

            &:hover {
              box-shadow: 0 0 3px 0px black;
            }

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }

        .layout-buttons-container {
          width: 30%;
          display: flex;
          flex-flow: column;
          align-items: center;

          h5 {
            margin-bottom: 20px;
          }

          .decoration-layout-option {
            width: 100%;

            button {
              height: 50px;
            }
          }

          @media (max-width: 576px) {
            width: 100%;
            flex-flow: row;
            flex-wrap: wrap;
            justify-content: space-evenly;

            h5 {
              width: 100%;
            }

            .decoration-layout-option {
              width: 30%;
            }
          }
        }

        @media (max-width: 576px) {
          flex-wrap: wrap;
          justify-content: space-around;
          padding: 0;

          & > * {
            margin-bottom: 2rem;
            width: 50%;
          }
        }
      }

      .snap-card-footer {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: white;
        /* position: absolute; */
        left: 0;
        bottom: 0;

        .decorations-save-button {
          background: black;
          color: whitesmoke;
          display: flex;
          width: 100%;
          padding: 1rem 0;
          justify-content: center;
          border: none;
          font-family: "ForzaBold";
          text-transform: uppercase;
          transition: all 0.2s;
        }

        .save-decorations-options {
          display: flex;
          justify-content: center;
          padding: 10px 0;

          label {
            color: #666;
            font-size: 14px;
          }

          .radio-buttons-container {
            display: flex;
            .radio-button-container {
              display: flex;
              flex-direction: row-reverse;
              align-items: center;
              padding: 0 3px;
              label {
                cursor: pointer;
                padding-left: 2px;
              }
              input {
                cursor: pointer;
                filter: grayscale(1);
              }
            }
          }

          @media (max-width: 768px) {
            flex-direction: column;
            text-align: center;

            label {
              margin-bottom: 0.5rem;
            }

            .radio-buttons-container {
              flex-direction: column;
              text-align: center;
              justify-content: center;
              align-items: center;

              .radio-button-container {
                margin-bottom: 1rem;
                align-items: center;
                label {
                  margin-bottom: 0 !important;
                }
              }
            }
          }
        }

        @media (max-width: 768px) {
          position: initial;
          flex-direction: column-reverse;
          margin-top: auto;

          .save-decorations-options {
            border-top: 2px solid grey;
            background: #fafafa;
          }
        }
      }

      &.loading-snap-card {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
      }
    }

    @media (max-width: 768px) {
      height: 100%;

      .snap-card.decorations-snap-card {
        padding: 1rem;
      }
    }
  }
}
</style>