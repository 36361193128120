<template>
  <div class="color-picker mr-auto" v-if="selected">
    <div class="color-picker-swatch" @click.stop="triggerPicker">
      <div
        class="color-picker-radius box-shadow"
        v-bind:style="{ backgroundColor: selected.hex }"
      ></div>
      <span>{{ selected.family_name }}</span>
    </div>
    <div
      class="color-picker-list box-shadow flex-column flex-wrap"
      v-bind:class="{ active: isActive }"
    >
      <!-- Create a ColorSwatch for each color available in the state -->

      <template>
        <!-- Color pickers for Snapsheets -->
        <ColorSwatch
          v-bind:key="color.id"
          v-for="color in snapsheetsColors"
          :color="color"
          :field="field"
          v-on="{ emitSelectColor }"
        />
      </template>
    </div>
  </div>
</template>

<script>
import ColorSwatch from "@/components/Snapsheet/ColorSwatch.vue";
import { mapState } from "vuex";
export default {
  props: ["selected", "isActive", "field"],
  name: "ColorPicker",
  computed: {
    ...mapState("snapsheet", ["snapsheetColorsIds", "isEditModeActive"]),
    ...mapState(["colors"]),
    snapsheetsColors() {
      return this.colors.filter((color) =>
        this.snapsheetColorsIds.some((colorId) => colorId == color.id)
      );
    },
  },
  methods: {
    triggerPicker() {
      this.$emit("on-open-picker", {
        field: this.field,
        event: !this.isActive,
      });
    },
    emitSelectColor(data) {
      this.$emit("emitSelectColor", data);
    },
  },
  watch: {
    isEditModeActive() {
      this.$emit("on-open-picker", {
        field: this.field,
        event: false,
      });
    },
  },
  components: {
    ColorSwatch,
  },
};
</script>

<style lang="scss" scoped>
#app {
  .color-picker-swatch {
    .color-picker-radius {
      width: 20px;
      height: 20px;
      border: 1px solid;
    }
  }

  .color-picker-list {
    max-height: 303px;
  }

  .color-picker {
    position: relative;
  }

  .color-picker-swatch {
    
    display: flex;
    align-items: center;
    &:hover{
      cursor: pointer;

    }
    .color-picker-radius {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 5px;
      border: 1px solid;
    }
    span {
      //font-family: $ubuntuRegular;
      font-size: 10px;
      line-height: 11px;
      letter-spacing: 0.76px;
      text-transform: lowercase;
      color: var(--buttonNotSelectedColor);
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }

  .color-picker-list {
    border: 1px solid;
    background-color: white;
    background: "#FFFFFF";
    display: none;
    margin-top: 5px;
    position: absolute;
    top: 20px;
    left: 0;
    .color-picker-swatch {
      padding: 10px;
    }
  }

  .color-picker-list.active {
    display: flex;
    position: absolute;
    z-index: 1;
    flex-wrap: wrap;
    width: 550px;
  }

  @media screen and (max-width: 768px) {
    .color-picker {
      position: initial;

      .color-picker-list {
        position: absolute;
        top: 2rem;
        left: 0;
        right: 0;
        margin: auto;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        overflow: scroll;

        &.active {
          width: auto;
        }

        .color-picker-swatch {
          width: 100%;
        }
      }
    }
  }
}
</style>