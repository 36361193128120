import Vue from 'vue'
import VueRouter from 'vue-router'
import Snapsheet from '../views/Snapsheet.vue'
import Locker from '../views/Locker.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/snapsheets/:dealer_code/:snapsheet_id/locker',
    name: 'Locker',
    component: Locker
  },

  {
    path: '/snapsheets/:dealer_code/:snapsheet_id/',
    name: 'Snapsheet',
    component: Snapsheet
  },
  {
    path: '/404',
    name: 'NotFound404',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
