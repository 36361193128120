<template>
  <div id="dealer-login-modal">
    <div class="modal">
      <div class="gradient"></div>
      <div class="modal-header">
        <img src="https://dealer-showroom-dev.vercel.app/images/logo.svg" />
        <div class="close-modal" @click="closeModal()">X</div>
      </div>
      <div v-if="!eventLoading" class="modal-body">
        <h5>Please enter the Dealer passcode to turn "Dealer Mode" on</h5>
        <input
          type="text"
          v-model="passcode"
          maxlength="20"
          v-on:keyup="keyMonitor"
          ref="inputField"
        />
        <small v-if="error" class="error">An error has ocurred</small>
        <button
          class="btn btn-black"
          :class="{ disabled: error }"
          type="button"
          @click="submit()"
        >
          CONFIRM
        </button>
      </div>
      <div v-if="!eventLoading" class="modal-footer">
        <p>
          If you need to find your passcode, please check “My Settings” in your
          Gameplan Dealer Panel.
        </p>
      </div>
      <div v-if="eventLoading" class="modal-loader">
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Loader from "@/components/Loader.vue";

export default {
  name: "DealerLoginModal",
  computed: {
    ...mapState("snapsheet", ["snapsheetId"]),
    ...mapState(["eventLoading", "error"]),
    ...mapGetters(["dealerMode"]),
  },
  components: { Loader },
  methods: {
    ...mapActions(["dealerLogin", "resetError"]),
    keyMonitor(event) {
      if (this.error) this.resetError();
      if (!this.error) {
        if (event.key == "Enter") {
          this.submit();
        }
      }
    },
    submit() {
      if (!this.passcode || !this.snapsheetId || this.error) return false;
      this.dealerLogin({
        passcode: this.passcode,
        snapsheetId: this.snapsheetId,
      });
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
  data() {
    return {
      passcode: "",
    };
  },
  watch: {
    // Closes modal after successfully logged in
    dealerMode() {
      this.$emit("closeModal");
      //todo scroll to top?
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.inputField.focus();
    });
  },
};
</script>

<style lang="scss" scoped>
#app {
  #dealer-login-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    .modal {
      width: 400px;
      .gradient {
        background-image: linear-gradient(
          to right,
          rgb(5, 130, 131),
          rgb(5, 84, 236),
          rgb(113, 38, 133),
          rgb(223, 6, 6)
        );
        height: 5px;
      }
      .modal-header {
        background-color: #000;
        display: flex;
        justify-content: center;
        position: relative;
        img {
          padding: 15px;
          width: 250px;
        }
        .close-modal {
          color: white;
          position: absolute;
          right: 10px;
          top: 5px;
          font-family: "ForzaBold";
          cursor: pointer;
        }
      }
      .modal-body {
        border: 1px solid #000;
        border-bottom: none;
        background-color: #fff;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h5 {
          font-family: "SlateStd";
          font-size: 14px;
          font-weight: normal;
        }
        input {
          width: 235px;
          margin: 10px 0;
          padding: 5px;
          font-size: 24px;
          height: 45px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
          border-width: 1px;
        }
        button {
          width: 235px;
          margin: 10px 0;
          padding: 5px;
          background-color: #000;
          color: #fff;
          font-size: 18px;
          height: 45px;
          cursor: pointer;
          opacity: 1;
          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
        .error {
          color: red;
        }
      }
      .modal-loader {
        border: 1px solid #000;
        background-color: #fff;
        padding: 50px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .modal-footer {
        background-color: #fff;
        display: flex;
        justify-content: center;
        padding: 0 25px 10px;
        border: 1px solid #000;
        border-top: none;
        text-align: center;
        p {
          font-size: 14px;
        }
      }
    }
  }
}
</style>