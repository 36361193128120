import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//
import GameplanLocker from '@axlot-team/gameplan-locker';
import "@axlot-team/gameplan-locker/dist/gameplan-locker.css";


// import plugin
import VueToastr from "vue-toastr";
// use plugin
Vue.use(VueToastr, {
  "closeButton": false,
  "debug": false,
  "newestOnTop": false,
  "progressBar": false,
  "defaultPosition": "toast-top-center",
  "preventDuplicates": false,
  "onclick": null,
  "timeout ": 100000,
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
})

Vue.use(GameplanLocker, { store })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
