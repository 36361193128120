<template>
  <div class="decoration-layout-option">
    <button
      type="button"
      class="layout-button"
      :class="{ 'active-layout': editableProduct.activeLayoutId == layout.id }"
      @click="applyActiveLayout(layout.id)"
    >
      {{ layout.name }}
    </button>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "SnapsheetDecorationLayoutOption",

  props: ["layout"],

  computed: {
    ...mapState("snapsheet", ["editableProduct"]),
  },

  methods: {
    ...mapMutations("snapsheet", ["applyActiveLayoutId"]),

    applyActiveLayout(layoutId) {
      this.applyActiveLayoutId(layoutId);
      this.$emit("applyActiveLayout", layoutId);
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  .layout-button {
    background: white;
    width: 100%;
    margin: 5px auto;
    max-width: 200px;
    padding: 5px;
    text-transform: uppercase;
    font-weight: bold;
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    min-height: 50px;
    max-height: 60px;
    outline: none;
    &.active-layout {
      background: #000;
      color: white;
    }
  }
}
</style>