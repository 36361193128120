var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"graphics-modal"},[_c('button',{staticClass:"close-modal",on:{"click":_vm.close}},[_vm._v("x")]),(_vm.apiLoading)?_c('Loader'):_c('div',{staticClass:"graphics-modal-edit-mode"},[_c('div',{staticClass:"graphic-modal-edit-mode-content"},[_c('div',{staticClass:"graphic-modal-edit-panel"},[_c('div',{staticClass:"graphic-panel-items"},_vm._l((Array(9)
                .fill()
                .map(function (_, i) { return i + 1; })),function(item){return _c('div',{key:item,staticClass:"graphic-panel-item",class:{ active: _vm.position === item },on:{"click":function($event){return _vm.setPosition(item)}}},[_c('img',{staticClass:"graphic-panel-item-image",attrs:{"src":'/images/positions/' +
                  item +
                  (_vm.position === item ? '_active' : '') +
                  '.svg'}})])}),0),_c('div',{staticClass:"graphic-zoom-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.zoomLevel),expression:"zoomLevel"}],staticClass:"graphic-zoom",attrs:{"type":"range","min":"1","max":_vm.sizeRanges.length},domProps:{"value":(_vm.zoomLevel)},on:{"__r":function($event){_vm.zoomLevel=$event.target.value}}}),_c('div',{staticClass:"graphic-zoom-label"},[_vm._v(" "+_vm._s(_vm.zoomLabel)+" ")])]),_c('div',{staticClass:"graphic-modal-edit-mode-actions"},[_c('div',{staticClass:"btn black-btn graphic-modal-edit-mode-action",on:{"click":_vm.save}},[_c('button',[_vm._v("apply")])])])]),_c('div',{staticClass:"graphic-modal-transparent-bg"},[_c('div',{ref:"screenContainer",staticClass:"graphic-modal-edit-preview",style:({ alignItems: _vm.alignItems, justifyContent: _vm.justifyContent }),attrs:{"id":"graphic-preview"}},[_c('img',{staticClass:"graphic-modal-edit-preview-image",style:({
                maxWidth: _vm.zoomStyle,
                maxHeight: _vm.zoomStyle,
              }),attrs:{"src":_vm.editableScreenLogo}})])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }