<template>
  <section v-if="isModalOpen" id="modal-container" @click.self="emitCloseModal">
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: "Modal",
  props: {
    isModalOpen: {
      type: Boolean,
    },
  },
  methods: {
    emitCloseModal() {
      this.$emit("onCloseModal");
    },
  },
};
</script>

<style lang="scss">
#modal-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.144);
  z-index: 99999;
}
</style>