<template>
  <section id="pages-container" :class="classList">
    <!-- Prev page Button -->
    <button
      v-if="allowArrowButtons"
      :class="{ inactive: !allowPrevButton }"
      @click="handlePrevPage"
      class="page-button"
    >
      <img src="/images/arrow-icons.svg" alt="prev button arrow" />
    </button>

    <template v-if="pages.length">
      <template v-for="(page, index) in actualPages">
        <slot :page="page" :pageId="index + firstActualPage"></slot>
      </template>
      <slot name="hidding-modal"></slot>
    </template>

    <!-- Next page button -->
    <button
      v-if="actualPagesAreEven && allowArrowButtons"
      @click="handleNextPage"
      :class="{ inactive: !allowNextButton }"
      class="page-button"
    >
      <img src="/images/arrow-icons.svg" alt="next button arrow" />
    </button>
    <SnapsheetFooter
      :dealerMode="dealerMode"
      :paginatedPages="paginatedPages"
      :actualPaginatedPageSelected="actualPaginatedPageSelected"
      v-on="{ changePage }"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SnapsheetFooter from "@/components/SnapsheetFooter.vue";
export default {
  name: "PagesContainer",

  props: {
    type: {
      type: String,
    },
    pages: {
      type: Array,
    },
  },

  components: { SnapsheetFooter },

  data() {
    return {
      firstActualPage: 1,
      screenWidth: window.innerWidth,
    };
  },

  created() {
    this.firstActualPage = this.actualPage;
  },

  computed: {
    ...mapState(["loading", "dealer"]),
    ...mapState("snapsheet", ["actualPage"]),

    isMobile() {
      return this.screenWidth <= 1024;
    },

    dealerApiToken() {
      return this.dealer.api_token;
    },

    dealerMode() {
      return this.dealerApiToken && this.dealerApiToken.length;
    },

    classList() {
      return {
        "template-snapsheet-container": this.isSnapsheet,
        "without-arrows": !this.allowArrowButtons,
      };
    },

    isSnapsheet() {
      return this.type === "snapsheet";
    },

    actualPages() {
      if (this.isMobile) {
        return [this.pages[this.firstActualPage - 1]];
      } else {
        return this.pages.slice(
          this.firstActualPage - 1,
          this.firstActualPage + 1
        );
      }
    },

    actualPagesAreEven() {
      if (this.isMobile) return true;
      return this.actualPages.length % 2 === 0;
    },

    paginatedPages() {
      if (this.isMobile) {
        return this.pages.length;
      }

      return this.pages.length % 2 === 0
        ? this.pages.length / 2
        : (this.pages.length + 1) / 2;
    },

    actualPaginatedPageSelected: {
      get: function () {
        if (this.isMobile) return this.firstActualPage;
        return (this.firstActualPage + 1) / 2;
      },
      set: function (number) {
        if (this.isMobile) this.firstActualPage = number;
        if (!this.isMobile) this.firstActualPage = number * 2 - 1;
      },
    },

    numberOfPages() {
      return this.pages.length;
    },

    allowArrowButtons() {
      if (this.isMobile) {
        return this.numberOfPages > 1;
      }
      return this.numberOfPages > 2;
    },

    allowNextButton() {
      if (this.isMobile) {
        return this.firstActualPage !== this.numberOfPages;
      }
      return (
        this.firstActualPage + 1 !== this.numberOfPages &&
        this.firstActualPage !== this.numberOfPages
      );
    },

    allowPrevButton() {
      return this.firstActualPage !== 1;
    },
  },

  methods: {
    ...mapActions("snapsheet", ["setPage"]),
    handlePrevPage() {
      if (this.allowPrevButton)
        this.firstActualPage = this.isMobile
          ? this.firstActualPage - 1
          : this.firstActualPage - 2;
    },

    handleNextPage() {
      if (this.allowNextButton)
        this.firstActualPage = this.isMobile
          ? this.firstActualPage + 1
          : this.firstActualPage + 2;
    },

    changePage(number) {
      this.actualPaginatedPageSelected = number;
      this.setPage(number * 2 - 1);
    },

    onResize() {
      this.screenWidth = window.innerWidth;
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss" >
#pages-container {
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 10fr 10fr 1fr;
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 1366px;

  &.without-arrows {
    grid-template-columns: repeat(2, 1fr);
    width: 70%;
  }

  h2 {
    text-transform: uppercase;
  }

  button.page-button {
    height: 300px;
    background: black;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 1rem;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.541);
      transform: scale(1.1);
    }
    img {
      height: 100px;
      width: 50px;
    }

    &:nth-of-type(odd) {
      margin-left: 0;
      margin-right: 1rem;
      transform: rotate(180deg);
      &:hover {
        transform: rotate(180deg) scale(1.1);
      }
    }

    &.inactive {
      background: grey;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 10fr 1fr;

    &.without-arrows {
      grid-template-columns: repeat(1, 1fr);
      width: 70%;
    }
  }

  @media screen and (max-width: 576px) {
    button.page-button {
      height: 100px;
      position: sticky;
      top: 1.5rem;
      right: 0;
      margin-top: 40vh;

      &:nth-of-type(odd) {
        left: 0;
      }
    }
  }

  @media screen and (max-width: 375px) {
    grid-template-columns: 25px 10fr 25px;

    button.page-button {
      margin-left: 0;

      &:nth-of-type(odd) {
        margin-right: 0;
      }

      img {
        height: 25px;
        width: 25px;
      }
    }
  }
}
.template-snapsheet-container {
  display: flex;
}
</style>