<template>
  <!--begin::Pagination-->
  <nav role="navigation" aria-label="Pagination Navigation" class="pagination">
    <!-- Pagination links-->

    <!-- Prev Button -->
    <button
      @click="selected_page !== 1 && emitChangePage(selected_page - 1)"
      class="nav-link arrow-link"
      :class="{
        'btn-light ': isFirstPageSelected,
        'btn-hover-primary ': !isFirstPageSelected,
      }"
    >
      <span aria-label="previous page">«</span>
    </button>

    <!-- First link -->
    <button
      @click="emitChangePage(1)"
      :class="{ active: 1 === selected_page }"
      class="nav-link"
    >
      1
    </button>

    <!-- Empty dots -->
    <button v-if="showFirstDots" href="#" class="nav-link">...</button>

    <!-- Middle Links -->
    <button
      v-for="pageNumber in middlePages"
      :key="'page-' + pageNumber"
      @click="emitChangePage(pageNumber)"
      :class="{ active: pageNumber === selected_page }"
      class="nav-link"
    >
      {{ pageNumber }}
    </button>

    <!-- Empty dots -->
    <button v-if="showLastDots" href="#" class="nav-link">...</button>

    <!-- Last Link -->
    <button
      @click="emitChangePage(total_pages)"
      :class="{ active: total_pages === selected_page }"
      class="nav-link"
    >
      {{ total_pages }}
    </button>

    <!-- Next Button -->
    <button
      @click="!isLastPageSelected && emitChangePage(selected_page + 1)"
      class="nav-link arrow-link"
      :class="{
        'btn-light': isLastPageSelected,
        'btn-hover-primary ': !isLastPageSelected,
      }"
    >
      <span aria-label="next page">»</span>
    </button>
  </nav>
  <!--end:: Pagination-->
</template>

<script>
export default {
  name: "Pagination",
  props: ["total_pages", "selected_page", "total_items"],
  computed: {
    middlePages: function () {
      //This function creates an array with the page's links
      function linksArrayRange(start, end) {
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
      }

      if (!this.total_pages) return [];

      if (this.total_pages && this.total_pages > 5) {
        //Return an array with 3 pages, they will be the links
        let links;
        //First 3 links
        if (this.selected_page <= 3) {
          links = linksArrayRange(2, 4);
        } else if (this.selected_page >= this.total_pages - 2) {
          //Last 3 links
          links = linksArrayRange(this.total_pages - 3, this.total_pages - 1);
        } else {
          links = linksArrayRange(
            this.selected_page - 1,
            this.selected_page + 1
          );
        }
        return links;
      } else {
        if (this.total_pages > 1) {
          return linksArrayRange(2, this.total_pages - 1);
        } else {
          return [];
        }
      }
    },

    showFirstDots: function () {
      return this.total_pages > 5 && this.selected_page > 3;
    },

    showLastDots: function () {
      return this.total_pages > 5 && this.selected_page < this.total_pages - 2;
    },

    isFirstPageSelected() {
      return this.selected_page === 1;
    },

    isLastPageSelected() {
      return this.selected_page === this.total_pages;
    },
  },
  methods: {
    emitChangePage(pageNumber) {
      this.$emit("changePage", pageNumber);
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  nav.pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    border: 2px solid #c2cbcb;
    border-radius: 5px;

    .nav-link {
      padding: 0.5rem;
      background: none;
      border: none;
      cursor: pointer;
      transition: all 0.1s;
      flex: 1 1 0px;

      &:hover {
        background: #e0e4e4;
      }

      &.active {
        background: #c2cbcb;
        outline: none;
        color: white;
      }
    }

    .arrow-link {
      color: #c2cbcb;
      &:hover {
        color: black;
      }
    }

    .arrow-link:first-of-type {
      border-right: 1px solid #c2cbcb;
    }
    .arrow-link:last-of-type {
      border-left: 1px solid #c2cbcb;
    }
  }
}
</style>